import React from 'react';
import NavBar from './navbar';

const AboutUs = () => {
  return (
    <>
      < NavBar />
          <div className="col">
            <h1>About Us</h1>
            <p>
              Our acclaimed administration and teaching team collaborate with our students in a collaborative environment. We incorporate new methods of thinking, new ways of innovating, and new problem-solving experiences. Our primary goal is to connect our students with potential careers, inspiring them to use their abilities and face the world with confidence. Our Programs are part of a strong core organization that runs with dedication, integrity, and honesty.

              Our Vision: We envision a future in which every learner is prepared to flourish in an ever-changing world.

              Our Mission: To achieve accessible, equitable, and efficient education for all students, we develop breakthrough research, great programs, and outstanding leaders.
            </p>
          </div>
    </>
  );
};

export default AboutUs;

