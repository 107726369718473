import React from 'react';
import NavBar from './navbar';
import './servicesStyles.css'

function Services() {
    return (
        <>
            < NavBar />
            <div className="row">

                <div className="col">
                    <h1>Services</h1>
                    <p>
                        The main objective of this website is to evaluate each student's performance with helpful features like:
                    </p>
                    <ul>
                        <li>Individual Progress Tracking</li>
                        <li>Analyzing Reports</li>
                        <li>Important Updates</li>
                        <li>Student Feedback and Advising</li>
                    </ul>
                </div>
                <div className="col">
                    <div className="Tab Tab1">
                        <h3>Progress Tracking</h3>
                    </div>
                    <div className="Tab Tab2">
                        <h3>Reports</h3>
                    </div>
                    <div className="Tab Tab3">
                        <h3>Announcements</h3>
                    </div>
                    <div className="Tab Tab4">
                        <h3>Feedback</h3>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Services;