import React from "react";
import './Qao.css'
import Header from '../Coordinator/Header'

class chat extends React.Component {
  render() {
    return (
      <div>
        <div className="main">
        < Header />

          <div className="chat-container">
            <div className="chat-header">Chat Room</div>
            <div className="chat-messages">
              <div className="message">User1: Hello!</div>
              <div className="message">User2: Hi there!</div>
            </div>
            <div className="chat-input">
              <input type="text" placeholder="Type your message..." />
              <button>Send</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default chat;

