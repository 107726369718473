import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
/*import '../lrstyle.css';*/
import '../chatbotstyle.css';
import '../student_exams_dashboard/student-exams-dashboard.css';
import logo from '../images/logo.png';
import API_BASE_URL from '../../config';
import Header from '../../Coordinator/Header';

export default function StudentExamsDashboard() {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    const userEmail = userDetails && userDetails.email;
    const [scores, setScores] = useState({});
    const navigate = useNavigate(); // Using React Router's useNavigate
    useEffect(() => {
        const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
        const userEmail = userDetails && userDetails.email;
        if (userEmail) {
            <p> {userEmail}</p>
            fetch(`${API_BASE_URL}/api/scores.php`, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-type": "application/json"
                },
                body: JSON.stringify({ email: userEmail })
            })
                .then(response => response.json())
                .then(data => {
                    let scoreObj = {};
                    data.forEach(item => {
                        scoreObj[`Exam${item.exam_no}`] = item.scoreValue;
                    });
                    setScores(scoreObj);
                })
                .catch(error => console.error(error));
        }
    }, []);
    const handleLogout = () => {
        sessionStorage.removeItem('userDetails'); // Clear the userDetails
        navigate('/Login'); // Redirect to the Login page using navigate
    }

    return (
        <>
            < Header authPage='student' />

            <div class="col1">
                <div className="Tab Tab4">
                    <div className="overlay-text">
                        Exam1 {scores.Exam1 && `Score: ${scores.Exam1}`}
                    </div>
                </div>
                <div className="Tab Tab5">
                    <div className="overlay-text">
                        Exam2 {scores.Exam2 && `Score: ${scores.Exam2}`}
                    </div>
                </div>


                <div className="Tab Tab6">
                    <div className="overlay-text">
                        Exam3 {scores.Exam3 && `Score: ${scores.Exam3}`}
                    </div>
                </div>

                <div className="s-chat-container">
                    <div className="s-chat-header">
                        Chat Room
                    </div>
                    <div className="s-chat-messages">
                        <div className="s-message">User1: Hello!</div>
                        <div className="s-message">User2: Hi there!</div>
                    </div>
                    <div className="s-chat-input">
                        <input type="text" placeholder="Type your message..."></input>
                        <button>Send</button>
                    </div>
                </div>
            </div>
        </>
    );
}
