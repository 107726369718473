import React, { useState, useEffect } from 'react';
import Header from './Header';
// import { useNavigate } from "react-router-dom";
import './defaultStyle.css';
import API_BASE_URL from '../config';

const ProgramOverview = () => {
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Send a GET request to your API endpoint
                const response = await fetch(`${API_BASE_URL}/api/get_all_courses.php`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });

                console.log(`courses resp - ${JSON.stringify(response)}`);

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const data = await response.json();
                setCourses(data); // Assuming the API response is an array of courses
            } catch (error) {
                console.error('Error fetching data:', error.message);
                // Handle error as needed
            }
        };

        fetchData();
    }, []);

    return (
        <>
            < Header authPage='programcoordinator' />


            <div className='col'>
                <h2>Program Overview</h2>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Instructor</th>
                            <th>Progress Status</th>
                            <th>Students Enrolled</th>
                            <th>Aligns with Program Objectives</th>
                        </tr>
                    </thead>
                    <tbody>
                        {courses.map(course => (
                            <tr key={course.id}>
                                <td>{course.id}</td>
                                <td>{course.code}</td>
                                <td>{course.title}</td>
                                <td>{course.instructor}</td>
                                <td>{course.progress_status}</td>
                                <td>{course.students_enrolled}</td>
                                <td>{course.aligns_with_objectives}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ProgramOverview;
