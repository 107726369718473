import React, { useEffect, useState } from 'react';
import styles from './manageUser.module.css';
import axios from 'axios';
import API_BASE_URL from '../config';
import Header from '../Coordinator/Header'

function UserPermission() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        // Fetch unverified users from the server when the component mounts
        axios.get(`${API_BASE_URL}/api/getUnverifiedUser.php`)
            .then(response => {
                console.log(response.data);
                setUsers(response.data);
            })
            .catch(error => {
                console.error('Error fetching unverified users:', error);
            });
    }, []);

    // Handle approval of a user
    const handleApprove = (user) => {
        const formDetails = new FormData();
        formDetails.append("email", user.email);
        formDetails.append("verified", 1);
        // Send a request to approve the user
        axios.post(`${API_BASE_URL}/api/changeVerification.php`, formDetails, {
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then(response => {
            // Update the user list with the updated verification status
            setUsers(users.map(u => (u.email === user.email ? { ...u, verified: 1 } : u)));
        })
            .catch(error => {
                console.error('Error approving user:', error);
            });
    };

    // Handle disapproval of a user
    const handleDisapprove = (user) => {
        // Send a request to disapprove the user
        const formDetails = new FormData();
        formDetails.append("email", user.email);
        formDetails.append("verified", -1);
        // Send a request to approve the user
        axios.post(`${API_BASE_URL}/api/changeVerification.php`, formDetails, {
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then(response => {
            // Update the user list with the updated verification status
            setUsers(users.map(u => (u.email === user.email ? { ...u, verified: -1 } : u)));
        })
            .catch(error => {
                console.error('Error disapproving user:', error);
            });
    };

    return (
        <>
            < Header authPage='admin' />

            <div className="col">
                <div className={styles.manageUsersContainer}>
                    <h2>Verify Users</h2>

                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users && users.map((user, index) => (
                                <tr key={index}>
                                    <td>{user.firstname}</td>
                                    <td>{user.email}</td>
                                    <td>
                                        {parseInt(user.verified, 10) === 0 ? (
                                            <>
                                                <button
                                                    style={{ color: 'white', background: 'green', borderRadius: '3px' }}
                                                    onClick={() => handleApprove(user)}
                                                >
                                                    Approve
                                                </button>
                                                <button
                                                    style={{ color: 'white', background: 'red', borderRadius: '3px', marginLeft: '5px' }}
                                                    onClick={() => handleDisapprove(user)}
                                                >
                                                    Disapprove
                                                </button>
                                            </>
                                        ) : (
                                            user.verified === 1 ? 'Approved' : 'Disapproved'
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default UserPermission;
