// import logo from './logo.svg'
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "./App.css";
import Navbar from "./commonComponents/navbar";
import Admin from "./Admin/adminDashboard";
import ManageUsers from "./Admin/manageUsers";
import MonitorActivity from "./Admin/monitorActivity";
import UserPermission from "./Admin/userPermission";
import AdminProfile from "./Admin/adminProfile";
import AdminEditProfile from "./Admin/adminEditProfile";
import CoordinatorDashboard from "./Coordinator/CoordinatorDashboard";
import CoordinatorProfile from "./Coordinator/CoordinatorProfile";
import PerformanceMonitoring from "./Coordinator/PerformanceMonitoring";
import StudentsPerformance from "./Coordinator/StudentsPerformance";
import CoursePerformance from "./Coordinator/CoursePerformance";
import ProgramOverview from "./Coordinator/ProgramOverview";
import CurriculumDevelopment from "./Coordinator/CurriculumDevelopment";
import ProgramEvaluation from "./Coordinator/ProgramEvaluation";

import ContactUs from "./commonComponents/Contact";
import About from "./commonComponents/About";
import Services from "./commonComponents/Services";
import ImageGen from "./commonComponents/image_gen";


import Login from "./commonComponents/login/student-login";
import Register from "./commonComponents/register/student-register";
import ForgotPassword from "./commonComponents/forgot_password/forgot-password";

import Conversations from "./Coordinator/Conversations";
import Piechart from "./QualityAssurance/Piechart";
import Policies from "./QualityAssurance/Policies";
import Courses from "./QualityAssurance/courses";
import QAO from "./QualityAssurance/QAO";
import Home from "./Home";
import Chat from "./QualityAssurance/chat";
import Curriculum from "./QualityAssurance/curriculum"; //
import PerformanceAssessment from "./QualityAssurance/performance-assessment";
import StudentFeedback from "./QualityAssurance/student-support-feedback";
import FacultyFeedback from "./QualityAssurance/Faculty-Feedback";
import Accreditation from "./QualityAssurance/Accredation";
import Report from "./QualityAssurance/Report";
import Instructors from "./Instructor/Instructors";
import Course from "./Instructor/Course";
import Course1 from "./Instructor/Course1";
import Assignments from "./Instructor/Assignments";
import Exams from "./Instructor/Exams";
import Announcments from "./Instructor/Announcments";
import Personal from "./Instructor/Personal";
import Appointments from "./Instructor/Appointments";
import Feedback from "./Instructor/Feedback";
import Examsnew from "./Instructor/examsnew";
import Updateexams from "./Instructor/updateexams";
import Grades from "./Instructor/Grades";

import ChatAdmin from "./Coordinator/ChatAdmin";
import ChatInstructor from "./Coordinator/ChatInstructor";
import ChatQA from "./Coordinator/ChatQA";
import ChatStudent from "./Coordinator/ChatStudent";

import StudentDashboard from "./Student/student_dashboard/student-dashboard";
import StudentProfile from "./Student/student_profile/student-profile";
import StudentCoursesDashboard from "./Student/student_courses_dashboard/student-courses-dashboard";
import StudentCourse1 from "./Student/student_courses1/student-courses1";
import StudentExamsDashboard from "./Student/student_exams_dashboard/student-exams-dashboard";
import StudentExam1 from "./Student/student_exam1/student-exam1";
import StudentBookAppointment from "./Student/student_book_appointment/student-book-appointment";
import StudentReports from "./Student/student_reports/student-reports";
import StudentPiechart from "./Student/student_piechart/student-piechart";
import StudentDiscussions from "./Student/student_discussions/student-discussions";
import Contactss from "./Instructor/contactss";
import Update from "./QualityAssurance/update";
import Create from "./QualityAssurance/create";
import CreateHw from "./Instructor/CreateHw";
import Updatehw from "./Instructor/updatehw";
import RealChat from "./chat";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/student/student-dashboard"
          element={<StudentDashboard />}
        ></Route>
        <Route
          path="/student/student-profile"
          element={<StudentProfile />}
        ></Route>
        <Route
          path="/student/student-courses-dashboard"
          element={<StudentCoursesDashboard />}
        ></Route>
        <Route
          path="/student/student-courses1"
          element={<StudentCourse1 />}
        ></Route>
        <Route
          path="/student/student-exams-dashboard"
          element={<StudentExamsDashboard />}
        ></Route>
        <Route path="/student/student-exam1" element={<StudentExam1 />}></Route>
        <Route
          path="/student/student-book-appointment"
          element={<StudentBookAppointment />}
        ></Route>
        <Route
          path="/student/student-reports"
          element={<StudentReports />}
        ></Route>
        <Route
          path="/student/student-piechart"
          element={<StudentPiechart />}
        ></Route>
        <Route
          path="/student/student-discussions"
          element={<StudentDiscussions />}
        ></Route>
        <Route
          path="/student/forgot-password"
          element={<ForgotPassword />}
        ></Route>

        <Route path="/CoordinatorProfile" element={<CoordinatorProfile />} />
        <Route path="/ProgramOverview" element={<ProgramOverview />} />
        <Route
          path="/CurriculumDevelopment"
          element={<CurriculumDevelopment />}
        />
        <Route path="/ProgramEvaluation" element={<ProgramEvaluation />} />
        <Route
          path="/PerformanceMonitoring"
          element={<PerformanceMonitoring />}
        />
        <Route path="/Conversations" element={<Conversations />} />
        <Route path="/StudentsPerformance" element={<StudentsPerformance />} />
        <Route path="/CoursePerformance" element={<CoursePerformance />} />
        <Route path="/Contact" element={<ContactUs />} />
        <Route path="/About" element={<About />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/ImageGen" element={<ImageGen />} />
        <Route path="/CreateHw" element={<CreateHw />} />
        <Route path="/updatehw/:id" element={<Updatehw />} />
        <Route path="/examsnew" element={<Examsnew />} />
        <Route path="/updateexams/:id" element={<Updateexams />} />
        <Route path="/policies" element={<Policies />}></Route>
        <Route path="/Grades" element={<Grades />} />

        <Route path="/Login" element={<Login />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/create" element={<Create />} />
        <Route path="/update/:id" element={<Update />} />
        <Route path="/Courses" element={<Courses />} />
        <Route path="/contactss" element={<Contactss />} />

        <Route path="/PieChart" element={<Piechart />} />
        <Route path="/ChatAdmin" element={<ChatAdmin />} />
        <Route path="/Report" element={<Report />} />
        <Route path="/Accreditation" element={<Accreditation />} />
        <Route path="/FacultyFeedback" element={<FacultyFeedback />} />
        <Route path="/StudentFeedback" element={<StudentFeedback />} />
        <Route
          path="/PerformanceAssessment"
          element={<PerformanceAssessment />}
        />
        <Route path="/Curriculum" element={<Curriculum />} />
        <Route path="/Feedback" element={<Feedback />} />
        <Route path="/Appointments" element={<Appointments />} />
        <Route path="/Personal" element={<Personal />} />
        <Route path="/Announcments" element={<Announcments />} />
        <Route path="/Exams" element={<Exams />} />
        <Route path="/Assignments" element={<Assignments />} />
        <Route path="/Course1" element={<Course1 />} />
        <Route path="/Course" element={<Course />} />
        <Route path="/ChatQA" element={<ChatQA />} />
        <Route path="/ChatStudent" element={<ChatStudent />} />
        <Route path="/ChatInstructor" element={<ChatInstructor />} />
        <Route path="/AdminProfile" element={<AdminProfile />} />
        <Route path="/AdminEditProfile/:email" element={<AdminEditProfile />} />
        <Route path="/UserPermission" element={<UserPermission />} />
        <Route path="/ManageUsers" element={<ManageUsers />} />
        <Route path="/MonitorActivity" element={<MonitorActivity />} />
        <Route path="/Navbar" element={<Navbar />} />
        <Route path="/policies" element={<Policies />}></Route>

        <Route path="/" element={<Home />} />
        <Route
          path="/chat"
          exact
          element={
            <PrivateRoute>
              <RealChat />
            </PrivateRoute>
          }
        />

        <Route
          path="/Admin"
          element={
            <PrivateRoute>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route
          path="/Coordinator"
          element={
            <PrivateRoute>
              <CoordinatorDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/Instructors"
          element={
            <PrivateRoute>
              <Instructors />
            </PrivateRoute>
          }
        />
        <Route
          path="/QA"
          element={
            <PrivateRoute>
              <QAO />
            </PrivateRoute>
          }
        />
        <Route
          path="/Student"
          element={
            <PrivateRoute>
              <StudentDashboard />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}
function PrivateRoute({ children }) {
  const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));

  if (!userDetails) {
    return <Navigate to="/login" />;
  }
  return children;
}

export default App;
