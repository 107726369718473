import React from 'react';
import './chatStyle.css';
import { Link } from 'react-router-dom';
import Header from './Header';

function ChatAdmin() {
    return (
        <div>
            < Header authPage='programcoordinator' />
            
            <header>
                <h1>Chat with Admin</h1>
            </header>
            <div className="chat-container">
                <div className="chat-messages">
                    <div className="message admin-message">
                        <p>Hello! How can I assist you today?</p>
                    </div>
                    <div className="message user-message">
                        <p>I have a question regarding my course creation.</p>
                    </div>
                    {/* Add more sample messages here */}
                </div>
                <div className="chat-input">
                    <input type="text" placeholder="Type your message..." />
                    <button>Send</button>
                </div>
            </div>

            <Link to="/Conversations" className="button">Back to Conversations</Link>

        </div>
    );
}

export default ChatAdmin;
