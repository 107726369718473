import React, { Component } from 'react';
import './Qao.css'
import Header from '../Coordinator/Header'

class Accreditation extends Component {
  render() {
    return (
      <div className="main">
          < Header authPage='qa' />

        <main>
          <section>
            <h2>Our Accreditation</h2>
            <p>
              Our University is accredited by AAQEP. Accreditation is a testament to our commitment to academic excellence and quality assurance. It ensures that our programs meet or exceed the highest standards of education.
            </p>
            <p>Currently, we hold the following accreditations:</p>
            <ul>
              <li>Association for Advancing Quality in Educator Preparation (AAQEP)</li>
              {/* Add more accreditations as needed */}
            </ul>
          </section>

          <section>
            <h2>Accreditation Process</h2>
            <p>
              Accreditation is a continuous procedure that includes regular evaluations and assessments. Our institution is subjected to a thorough accreditation evaluation on a regular basis to verify that we are meeting the relevant requirements.
            </p>
            <ul>
              <li>Self-study and Feedback Review</li>
              <li>Evaluation of programs and services</li>
              <li>Accrediting bodies make site visits.</li>
              {/* Add more details about the accreditation process */}
            </ul>
          </section>

          <section>
            <h2>Accreditation Documents</h2>
            <p>Here, you can access our accreditation documents and reports:</p>
            <br />
            <a href="accreditation_report_2023.pdf" target="_blank" rel="noopener noreferrer">
              2023 Accreditation Report
            </a>
            <br />
            <a href="self_study_2022.pdf" target="_blank" rel="noopener noreferrer">
              Assessment Report (2023)
            </a>
            <form action="" method="post" encType="multipart/form-data">
              <label htmlFor="fileInput">Select a file:</label>
              <input type="file" name="fileInput" id="fileInput" />
              <br />
              <input type="submit" value="Upload File" />
            </form>
          </section>
        </main>
      </div>
    );
  }
}

export default Accreditation;
