import React from 'react'
import { Link } from "react-router-dom";
/*import '../lrstyle.css';*/
import '../chatbotstyle.css';
import '../student_exams_dashboard/student-exams-dashboard.css';
import logo from '../images/logo.png';
import Header from '../../Coordinator/Header';

export default function StudentExams1() {
    return (
        <>
            < Header authPage='student' />

            <div className="lr-main">
             
                <div className="s-quiz-container">
                    <h1 style={{ textAlign: 'center' }}>Quiz</h1>
                    <div className="question">
                        <h2>1. Which PHP function converts characters into entities?</h2>
                        <label><input type="radio" name="q1" value="a" /> A. convertchars </label>
                        <label><input type="radio" name="q1" value="b" /> B. double_encode</label>
                        <label><input type="radio" name="q1" value="c" /> C. sunstr</label>
                        <label><input type="radio" name="q1" value="d" /> D. htmlspecialchars</label>
                    </div>

                    <div className="question">
                        <h2>2. How many tags are in an element in HTML?</h2>
                        <label><input type="radio" name="q2" value="a" /> A. 2</label>
                        <label><input type="radio" name="q2" value="b" /> B. 1</label>
                        <label><input type="radio" name="q2" value="c" /> C. 3</label>
                        <label><input type="radio" name="q2" value="d" /> D. 4</label>
                    </div>
                    <div className="question">
                        <h2>3. Which HTML tag is used to define internal style sheet?</h2>
                        <label><input type="radio" name="q2" value="a" /> A. script</label>
                        <label><input type="radio" name="q2" value="b" /> B. css</label>
                        <label><input type="radio" name="q2" value="c" /> C. style</label>
                        <label><input type="radio" name="q2" value="d" /> D. head</label>
                    </div>
                    <div className="lr-input-group">
                        <button type="submit" className="s-btn" name="quizsubmit">Submit</button>
                    </div>
                </div>
                <div className="s-chat-container">
                    <div className="s-chat-header">
                        Chat Room
                    </div>
                    <div className="s-chat-messages">
                        <div className="s-message">User1: Hello!</div>
                        <div className="s-message">User2: Hi there!</div>
                    </div>
                    <div className="s-chat-input">
                        <input type="text" placeholder="Type your message..."></input>
                        <button>Send</button>
                    </div>
                </div>
            </div>
        </ >
    );
}
