import React, { Component } from 'react';
import './Qao.css'
import logo from '../Student/images/logo.png';
import { Link } from "react-router-dom";
import Header from '../Coordinator/Header'

class CurriculumReview extends Component {
  render() {
    return (
      <div className="qao-main">
          < Header authPage='qa' />

        <div style={{ marginLeft: "220px", padding: "20px" }}>
          <div className="contentt">
            <h2>Curriculum Review</h2>
            
            <table>
              <thead>
                <tr>
                  <th>Review Type</th>
                  <th>Yes</th>
                  <th>No</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Are the course learning objectives clear and aligned with the program's overall learning outcomes?</td>
                  <td><input type="checkbox" /></td>
                  <td><input type="checkbox" /></td>
                </tr>
                <tr>
                  <td>Do the assessment methods (exams, assignments, projects) effectively measure students' understanding of the material?</td>
                  <td><input type="checkbox" /></td>
                  <td><input type="checkbox" /></td>
                </tr>
                <tr>
                  <td>Did the curriculum meet the changing needs of students and the job market?</td>
                  <td><input type="checkbox" /></td>
                  <td><input type="checkbox" /></td>
                </tr>
                <tr>
                  <td>Are there opportunities for collaborative learning or interactive activities within the course?</td>
                  <td><input type="checkbox" /></td>
                  <td><input type="checkbox" /></td>
                </tr>
              </tbody>
            </table>
    
            <div className="btn-container">
              <button className="btn">Submit</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CurriculumReview;
