import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
// import logo from '../../Student/images/logo.png';
import '../../Student/lrstyle.css';
import NavBar from '../navbar';
import API_BASE_URL from '../../config';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();


  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      console.log(`Calling reset_password`);
      const is_laravel = API_BASE_URL.slice(-4,) && API_BASE_URL.slice(-4,) === '8000';  
      const reset_pwd_url = `${API_BASE_URL}/api/reset_password${is_laravel ? '' : '.php'}`;
      const formData = new FormData();
      formData.append('resetEmail', email);

      const response = await axios.post(reset_pwd_url, formData);

      console.log(response.data);

      setinvalidTokenError(false);
      setMessage(response.data.message);
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };

  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  const [invalidTokenError, setinvalidTokenError] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showSetPasswordForm, setShowSetPasswordForm] = useState(false);

  useEffect(() => {
    // Function to make the API request using async/await
    const fetchTokenValidation = async (tokenFromURL) => {
      try {
        // Replace 'your-api-endpoint' with your actual API endpoint
        const response = await fetch(`${API_BASE_URL}/api/validate-reset-password.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Set the appropriate content type
          },
          body: JSON.stringify({ token: tokenFromURL }), // Send the token in the request body
        });

        const data = await response.json();

        console.log(`Data ${JSON.stringify(data)}`);

        if (data.success) {
          // Token is valid, set the user and show the "Set Password" form
          setUser(data.user);
          setToken(tokenFromURL);
          setShowSetPasswordForm(true);
        } else {
          // Token is invalid, show an error message or handle it as needed
          console.error('Invalid token');
          setinvalidTokenError(true);
        }
      } catch (error) {
        console.error('Error while making API request:', error);
      }
    };

    // Check if a token is available in the URL's query parameters
    const urlSearchParams = new URLSearchParams(window.location.search);
    const tokenFromURL = urlSearchParams.get('token');

    if (tokenFromURL) {
      // Token is available, make an API request to validate it
      fetchTokenValidation(tokenFromURL);
    }
  }, []);

  const handleSetPassword = async () => {
    try {
      if (password.length < 8) {
        alert('Password should be at least 8 characters long.');
        return;
      }

      if (password !== confirmPassword) {
        alert('Passwords do not match');
        return;
      }

      // Create a data object with the new password and token
      const data = {
        token: token,
        password: password,
      };

      // Send a POST request to your PHP backend
      const response = await fetch(`${API_BASE_URL}/api/set-password.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      console.log(`Response ${JSON.stringify(response)}`);

      if (response.ok) {
        setPassword('');
        setConfirmPassword('');
        setinvalidTokenError(false);
  
        // Use the useHistory hook to navigate to the login page
        setMessage('Password Reset Successful. Redirecting you to login page...');

        setTimeout(function () {
          setShowSetPasswordForm(false);
          navigate('/Login');
        }, 2000);
      } 
    } catch (error) {
      // Handle any network or other errors
      console.error('Error while setting password:', error);
      // Optionally, display an error message to the user
    }
  };

  return (
    <>
      <div className="main">
        <NavBar />
      </div>

      {invalidTokenError ? (<p>Your password reset link have expired. Please request a new reset link.</p>) : <></>}

      <div className="col">
        {showSetPasswordForm ? (
          <div>
            <h2>Set New Password</h2>

            <br />
            <br />
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <br />
            <label>Confirm Password:</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            <br />
            <br />

            <button onClick={handleSetPassword}>Set Password</button>
          </div>
        ) : (
          <div className="forgot-password-container">
            <h2>Password Reset</h2>
            <p>Enter your email address, and we will send you a link to reset your password:</p>

            <form onSubmit={handleForgotPassword}>
              <div className="input-group">
                <label htmlFor="reset_email">Email:</label>
                <input
                  type="email"
                  id="reset_email"
                  name="reset_email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <button className="btn" type="submit">
                Send Reset Link
              </button>
            </form>
          </div>
        )}
        
        {message && <p>{message}</p>}

      </div>
    </>
  );
}
