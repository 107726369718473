import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './defaultStyle.css';
import Header from './Header';

const CoordinatorDashboard = () => {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    // const userEmail = userDetails && userDetails.email;
    const userType = userDetails && userDetails.usertype;
    const navigate = useNavigate(); // Using React Router's useNavigate

    if (userType && userType !== 'programcoordinator') {
        console.log(`Unauthorized!`);
        switch (userType) {
            case "student":
                navigate("/Student");
                break;
            case "instructor":
                navigate("/Instructors");
                break;
            case "admin":
                navigate("/Admin");
                break;
            case "programcoordinator":
                navigate("/Coordinator");
                break;
            case "qa":
                navigate("/QA");
                break;
            default:
                navigate("/");
                break;
        }
    }

    return (
        <>
            < Header authPage='programcoordinator' />


            <div className="col">
                <Link to="/CoordinatorProfile">
                    <div className="Tab Tab1">
                        <div className="overlay-text">Profile</div>
                    </div>
                </Link>


                <Link to="/ProgramOverview">
                    <div className="Tab ProgramOverview">
                        <div className="overlay-text">Program Overview and Alignment</div>
                    </div>
                </Link>


                <Link to="/CurriculumDevelopment">
                    <div className="Tab CurriculumDev">
                        <div className="overlay-text">Curriculum Development and Updates</div>
                    </div>
                </Link>
                
                <Link to="/ProgramEvaluation">
                    <div className="Tab ProgramEval">
                        <div className="overlay-text">Program Evaluation</div>
                    </div>
                </Link>

                <Link to="/chat">
                    <div className='Tab Chat'>
                        <div className='overlay-text'>Chat</div>
                    </div>
                </Link>

                {/* <Link to="/PerformanceMonitoring">
                    <div className="Tab PerformanceMonitoring">
                        <div className="overlay-text">Performance Monitoring</div>
                    </div>
                </Link> */}

                {/* <Link to="/Conversations">
                    <div className="Tab Tab4">
                        <div className="overlay-text">Conversations</div>
                    </div>
                </Link > */}

            </div >
        </>
    );
}

export default CoordinatorDashboard;
