import React from 'react';
import './Qao.css'
import Header from '../Coordinator/Header'

const GraphComponent = () => {
  return (
    <div className="main">
          < Header authPage='qa' />


      <div className="bar-graph">
        <div className="bar" style={{ height: '50%', background: 'linear-gradient(to top, #FF6B6B, #FF8787)' }} data-label="Quiz 1"></div>
        <div className="bar" style={{ height: '80%', background: 'linear-gradient(to top, #3498DB, #5DADE2)' }} data-label="Quiz 2"></div>
        <div className="bar" style={{ height: '30%', background: 'linear-gradient(to top, #58D68D, #7DCEA0)' }} data-label="Quiz 3"></div>
      </div>
    </div>
  );
};

export default GraphComponent;
