import React, { useState, useEffect } from 'react';
import Header from './Header';
// import { useNavigate } from "react-router-dom";
import './defaultStyle.css';
import API_BASE_URL from '../config';

const CurriculumDevelopment = () => {
    const [courses, setCourses] = useState([]);

    const sampleData = [
        { id: 1, title: 'Course 1', instructor: 'Instructor A', progressStatus: '50%', studentsEnrolled: 30 },
        { id: 2, title: 'Course 2', instructor: 'Instructor B', progressStatus: '25%', studentsEnrolled: 20 },
        // Add more courses as needed
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Send a GET request to your API endpoint
                const response = await fetch(`${API_BASE_URL}/api/get_all_courses.php`, {
                // const response = await fetch(`${API_BASE_URL}/api/get_curriculum_data.php`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });

                console.log(`courses resp - ${JSON.stringify(response)}`);

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const data = await response.json();
                setCourses(data); // Assuming the API response is an array of courses
            } catch (error) {
                console.error('Error fetching data:', error.message);
                // Handle error as needed
            }
        };

        fetchData();
    }, []);

    return (
        <>
            < Header authPage='programcoordinator' />

            <div className='col'>
                <h2>Curriculum Updates and Development</h2>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Last Curriculum Update</th>
                            <th>Curriculum Version</th>
                            <th>Development Status</th>
                            {/* <th>Actions</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {courses.map(course => (
                            <tr key={course.id}>
                                <td>{course.id}</td>
                                <td>{course.code}</td>
                                <td>{course.title}</td>
                                <td>{course.last_curriculum_update}</td>
                                <td>{course.curriculum_version}</td>
                                <td>{course.development_status}</td>
                                {/* <td>
                                    {/* Add action links/buttons as needed */}
                                    {/* <button onClick={() => handleEditCurriculum(course.id)}>Edit Curriculum</button> */}
                                    {/* <button onClick={() => handleReviewCurriculum(course.id)}>Initiate Review</button> */}
                                {/* </td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default CurriculumDevelopment;
