import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
/*import '../lrstyle.css';*/
import '../chatbotstyle.css';
import '../student_discussions/student-discussions.css';
import logo from '../images/logo.png';
import Header from '../../Coordinator/Header';

export default function StudentDiscussions() {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    const userEmail = userDetails && userDetails.email;

    const [userMessage, setUserMessage] = useState('');

    // State to hold the list of messages
    const [messages, setMessages] = useState([
        { author: "John", text: "Does anyone have notes from yesterday's lecture?" },
        { author: "Alice", text: "Yes, I've uploaded them on the shared drive." }
    ]);
    const handleSendMessage = () => {
        if (userMessage.trim()) { // Prevent sending empty messages
            // For now, let's set a default author. You can later replace it with the logged-in user's name.
            const newMessage = { author: userEmail, text: userMessage.trim() };
            setMessages([...messages, newMessage]);
            setUserMessage(''); // Clear the input field after sending
        }
    };

    return (
        <>
            < Header authPage='student' />

            <div className="s-discussion-container">
                <h1>Student Discussion Board</h1>
                <div className="ss-messages">
                    <div className="ss-message">
                        {messages.map((message, index) => (
                            <div className="ss-message" key={index}>
                                <strong>{message.author}:</strong> {message.text}
                            </div>
                        ))}
                    </div>


                </div>
                <div className="ss-input-area">
                    <input
                        type="text"
                        placeholder="Type your message..."
                        value={userMessage}
                        onChange={(e) => setUserMessage(e.target.value)}
                    />
                    <button onClick={handleSendMessage}>Send</button>
                </div>
            </div>
        </ >
    );
}
