import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../config';
import Header from '../Coordinator/Header'

class CreatePolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      policyName: '',
      policyID: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const state = this.state;
    state[event.target.name] = event.target.value;
    this.setState(state);
  }

  handleSubmit(event) {
    event.preventDefault();

    axios({
      method: 'post',
      url: `${API_BASE_URL}/api/policyy.php`,
      data: this.state,
    })
      .then(function (response) {
        // Handle success
        console.log(response);
        alert('New Policy Successfully Added.');
      })
      .catch(function (response) {
        // Handle error
        console.error(response);
      });
  }

  render() {
    return (
      <div className="main">
          < Header authPage='qa' />

        <div></div>
        <div className="container">
          <h1 className="page-header text-center">Add New Policy</h1>
          <Link to="/Policies" className="update">
            Home
          </Link>
          <div className="col-md-12">
            <div className="panel panel-primary">
              <div className="panel-body">
                <form onSubmit={this.handleSubmit}>
                  <label>Policy Name</label>
                  <input
                    type="text"
                    name="policyName"
                    className="form-control"
                    value={this.state.policyName}
                    onChange={this.handleChange}
                  />

                  <label>Policy ID</label>
                  <input
                    type="text"
                    name="policyID"
                    className="form-control"
                    value={this.state.policyID}
                    onChange={this.handleChange}
                  />

                  <br />
                  <input
                    type="submit"
                    className="update"
                    value="Create Policy"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreatePolicy;
