import React from "react";
import { Link } from "react-router-dom";
import './Qao.css'
import Header from '../Coordinator/Header'

class Home extends React.Component {
  render() {
    return (
      <div>
        <head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </head>
        <div className="main">
          < Header authPage='qa' />

          <div className="form">
            <Link to="/Curriculum">
              <button className="btnn">CURRICULUM EVALUATION</button>
            </Link>
            <Link to="/StudentFeedback">
              <button className="btnn">STUDENT SUPPORT FEEDBACK</button>
            </Link>
            <Link to="/FacultyFeedback">
              <button className="btnn">FACULTY FEEDBACK</button>
            </Link>
            <Link to="/Report">
              <button className="btnn">REPORTS</button>
            </Link>
            <Link to="/chat">
              <button className="btnn">CHAT</button>
            </Link>
            <Link to="/Accreditation">
              <button className="btnn">ACCREDITATION</button>
            </Link>
            <Link to="/policies">
              <button className="btnn">POLICIES</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
