import React, { useState } from 'react';
import axios from 'axios';
import logo from '../Student/images/logo.png';
import { Link } from "react-router-dom";
import API_BASE_URL from '../config';
import Header from '../Coordinator/Header'

function FacultyFeedback() {
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

      if (email.length === 0) {
      alert("Email has been left blank!");
    } else if (feedback.length === 0) {
      alert("Feedback has been left blank!");
    } else {
      const url = 'http://localhost/xyz/faculty-feedback';
      let formData = new FormData();
      formData.append('email', email);
      formData.append('feedback', feedback);

      axios
      .post(url, formData)
        .then(response => alert(response.data))
        .catch(error => alert(error));
    }
  }

  return (
    <div className="qao-main">
          < Header authPage='qa' />

      <main className="qao-container">
        <h1>Student Feedback</h1>
        <form onSubmit={handleSubmit}>
          <br />
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <br />
          <label htmlFor="feedback">Provide Your Feedback:</label>
          <textarea
            id="feedback"
            name="feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            rows="6"
            required
          ></textarea>
          <button type="submit">Submit</button>
        </form>
      </main>
    </div>
  );
}

export default FacultyFeedback;
