import React from 'react';
import './Qao.css'
import Header from '../Coordinator/Header'

class YourComponent extends React.Component {
  render() {
    return (
      <div className="main">
          < Header authPage='qa' />


        <div className="col">
          <div className="Tab Tab7">
            <div className="content-wrapper">
              <a href="/" target="_blank">
                Syllabus
              </a>
            </div>
          </div>

          <div className="Tab Tab8">
            <div className="content-wrapper">
              <a href="/" target="_blank">
                Modules
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default YourComponent;
