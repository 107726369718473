import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
/*import '../lrstyle.css';*/
import '../chatbotstyle.css';
import '../student_exams_dashboard/student-exams-dashboard.css';
import logo from '../images/logo.png';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, Cell } from "recharts";
import API_BASE_URL from '../../config';
import Header from '../../Coordinator/Header';

export default function StudentPiechart() {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    const userEmail = userDetails && userDetails.email;
    const [scores, setScores] = useState({});
    const navigate = useNavigate(); // Using React Router's useNavigate
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']; // You can add or modify these as you see fit

    useEffect(() => {
        const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
        const userEmail = userDetails && userDetails.email;
        if (userEmail) {
            <p> {userEmail}</p>
            fetch(`${API_BASE_URL}/api/scores.php`, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-type": "application/json"
                },
                body: JSON.stringify({ email: userEmail })
            })
                .then(response => response.json())
                .then(data => {
                    let scoreObj = {};
                    data.forEach(item => {
                        scoreObj[`Exam${item.exam_no}`] = item.scoreValue;
                    });
                    setScores(scoreObj);
                })
                .catch(error => console.error(error));
        }
    }, []);
    const handleLogout = () => {
        sessionStorage.removeItem('userDetails'); // Clear the userDetails
        navigate('/Login'); // Redirect to the Login page using navigate
    }
    const transformedScores = Object.keys(scores).map((examKey) => ({
        name: examKey,
        value: scores[examKey]
    }));
    return (
        <>
            < Header authPage='student' />

            {/* <div className="navbar">
                <div className="icon">
                    <h2 className="logo"><img src={logo} alt="Edumetric"></img></h2>
                </div>
                <div className="headermenu">
                    <ul>
                        <li><a href="/About">AboutUs</a></li>
                        <li><a href="/services">Services</a></li>
                        <li><a href="/Contact">ContactUs</a></li>
                        <li><a href="sxg1200.uta.cloud">Blog</a></li>
                        {userEmail && <li className="user-email">Logged in as: {userEmail}</li>}
                        {userEmail && <li onClick={handleLogout} className="logout-btn">Logout</li>}
                    </ul>
                </div>
            </div> */}
            <div style={{ textAlign: "center" }}>
                <h2>Report</h2>
                <div className="chart-container">
                    <BarChart
                        width={600}
                        height={400}
                        data={transformedScores}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }
                        }
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="value" fill="#8884d8">
                            {
                                transformedScores.map((entry, index) => (
                                    <Cell key={index} fill={COLORS[index % COLORS.length]} />
                                ))
                            }
                        </Bar>
                    </BarChart>
                </div>
            </div>
        </>
    );
}
