import "./Chat.css";
import { useEffect, useState, useReducer, useImperativeHandle } from "react";
import React, { useRef } from "react";
import io from "socket.io-client"; // Import the 'io' function
import NavBar from "./commonComponents/navbar";
import API_BASE_URL from "./config";
import axios from "axios";
const is_local = API_BASE_URL.slice(-4,) && API_BASE_URL.slice(-4,) === '8000';  
const socket_url = `${is_local ? API_BASE_URL : 'https://chatwdm.azurewebsites.net' }`;

var socket = io(socket_url, {});


function RealChat() {
  const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
  const [username, setUsername] = useState(
    userDetails ? userDetails.email : "#" + Math.floor(Math.random() * 9999999)
  );
  const requestUsers = () => {
    socket.emit("requestUsers");
  };
  const [searchTerm, setSearchTerm] = useState("");
  const userListComponent = useRef();

  useEffect(() => {
    socket.emit("username", username);
  }, [username]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      <NavBar />

      <UserList
        username={username}
        ref={userListComponent}
        searchTerm={searchTerm}
      />

      <Messages username={username} />

      <MessageInput username={username} />

      {/* <input
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Search users..."
      /> */}

      <button onClick={requestUsers}>Refresh User List</button>
    </>
  );
}

const UserList = React.forwardRef(({ username, searchTerm }, ref) => {
  const [users, setUsers] = useState([]);

  // Listen for updates to the user list from the server
  useEffect(() => {
    const updateUsersListener = (updatedUsers) => {
      setUsers(updatedUsers);
    };

    // Register the listener
    socket.on("updateUsers", updateUsersListener);

    // Clean up the listener when the component unmounts
    return () => {
      socket.off("updateUsers", updateUsersListener);
    };
  }, [socket]);

  return (
    <ul className="users">
      {users.map((user) => (
        <li
          className={user.username === username ? "user my-id" : "user"}
          key={user.socketId}
        >
          {user.username}
        </li>
      ))}
    </ul>
  );
});

const Messages = ({ username }) => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // Fetch initial messages from the server
    axios.get(`${API_BASE_URL}/api/getmsgs.php`).then((res) => {
      setMessages(res.data);
    });

    // Clean up the socket event listener when the component unmounts
    return () => {
      socket.off("reciveMessage");
    };
  }, []);

  useEffect(() => {
    // Register the socket event listener for new messages
    const handleReceiveMessage = (data) => {
      console.log(messages, data);
      setMessages((prevMessages) => [...prevMessages, data]);
    };

    socket.on("reciveMessage", handleReceiveMessage);

    // Clean up the socket event listener when the component unmounts
    return () => {
      socket.off("reciveMessage", handleReceiveMessage);
    };
  }, [messages]);
  return (
    <div className="msg-box">
      {messages.map((message, index) => {
        return (
          <p className="message" key={index}>
            {" "}
            <span className={message.username == username ? "my-id" : ""}>
              {message.username + ": "}
            </span>
            {message.text}
          </p>
        );
      })}
    </div>
  );
};

const MessageInput = ({ username }) => {
  const [text, setText] = useState("");

  function messageHandler(e) {
    setText(e.target.value);
  }

  function sendMessage(e) {
    e.preventDefault();
    try {
      socket.emit("message", { username, text, id: 0 });
      axios.post(`${API_BASE_URL}/api/storemsgs.php`, { username, text });
      setText("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  }

  return (
    <form onSubmit={sendMessage} className="inp">
      <input
        className="msg-input"
        onChange={messageHandler}
        value={text}
        placeholder="Type your message..."
      />

      <button type="submit" className="s-buttn">
        Send
      </button>
    </form>
  );
};

export default RealChat;
