import React, { useState, useEffect } from 'react';
import Header from './Header';
import './defaultStyle.css';
import API_BASE_URL from '../config';

const ProgramEvaluation = () => {
    const [evaluations, setEvaluations] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Send a GET request to your API endpoint
                const response = await fetch(`${API_BASE_URL}/api/get_program_eval_data.php`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const data = await response.json();
                setEvaluations(data); // Assuming the API response is an array of evaluations
            } catch (error) {
                console.error('Error fetching data:', error.message);
                // Handle error as needed
            }
        };

        fetchData();
    }, []); // Empty dependency array ensures that this effect runs only once after the component mounts

    return (
        <>
            < Header authPage='programcoordinator' />

            <div className='col'>
                <h2>Program Evaluation</h2>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Course ID</th>
                            <th>Assessment Date</th>
                            <th>Assessment Type</th>
                            <th>Assessment Score</th>
                            <th>Student Feedback</th>
                            <th>Instructor Feedback</th>
                            <th>Overall Evaluation</th>
                        </tr>
                    </thead>
                    <tbody>
                        {evaluations.map((evaluation, index) => (
                            <tr key={index + 1}>
                                <td>{index + 1}</td>
                                <td>{evaluation.course_code}</td>
                                <td>{evaluation.assessment_date}</td>
                                <td>{evaluation.assessment_type}</td>
                                <td>{evaluation.assessment_score}</td>
                                <td>{evaluation.student_feedback}</td>
                                <td>{evaluation.instructor_feedback}</td>
                                <td>{evaluation.overall_evaluation}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ProgramEvaluation;
