import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../config';
import Header from '../Coordinator/Header';

class Create extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AssignmentName: '',
      assignment_id: '',
      CourseID: '',
      Duedate: '',
      file_path: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const state = this.state;
    state[event.target.name] = event.target.value;
    this.setState(state);
  }

  handleSubmit(event) {
    event.preventDefault();

    let formData = new FormData();
    formData.append('AssignmentName', this.state.AssignmentName);
    formData.append('assignment_id', this.state.assignment_id);
    formData.append('CourseID', this.state.CourseID);
    formData.append('Duedate', this.state.Duedate);
    formData.append('file_path', this.state.file_path);

    axios({
      method: 'post',
      url: `${API_BASE_URL}/api/assignment.php`,
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then(function (response) {
        // Handle success
        console.log(response);
        alert('New Assignment Successfully Added.');
      })
      .catch(function (response) {
        // Handle error
        console.error(response);
      });
  }

  render() {
    return (
        <div className="main">
          < Header authPage='instructor' />

      <div className="container">
        <h1 className="page-header text-center">Add New Assignment</h1>
        <Link to="/Assignments" className="update">
          Home
        </Link>
        <div className="col-md-12">
          <div className="panel panel-primary">
            <div className="panel-body">
              <form onSubmit={this.handleSubmit}>
                <label>Assignment Name</label>
                <input
                  type="text"
                  name="AssignmentName"
                  className="form-control"
                  value={this.state.AssignmentName}
                  onChange={this.handleChange}
                />

                <label>Assignment id</label>
                <input
                  type="text"
                  name="assignment_id"
                  className="form-control"
                  value={this.state.assignment_id}
                  onChange={this.handleChange}
                />

                <label>Course ID</label>
                <input
                  type="text"
                  name="CourseID"
                  className="form-control"
                  value={this.state.CourseID}
                  onChange={this.handleChange}
                />

                <label>Due date</label>
                <input
                  type="text"
                  name="Duedate"
                  className="form-control"
                  value={this.state.Duedate}
                  onChange={this.handleChange}
                />

                <label>File Path</label>
                <input
                  type="file"
                  name="file_path"
                  className="form-control"
                  value={this.state.file_path}
                  onChange={this.handleChange}
                />
                <br />
                <input
                  type="submit"
                  className="update"
                  value="Create Assignment"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default Create;
