import React from 'react';
import './instructor.css';
import Header from '../Coordinator/Header';

function InstructorAnnouncement() {
  return (
    <div>
      <div className="main">
      < Header authPage='instructor' />

        <div className="container-Announcement">
          <h1>Instructor Announcement</h1>
          <main>
            <form action="" method="post">
              <label htmlFor="Course">Select Course:</label>
              <select id="Course" name="Course" required>
                <option value="student1">Course 1</option>
                <option value="student2">Course 2</option>
              </select>

              <label htmlFor="message">Announcement Message:</label>
              <textarea id="message" name="message" rows="5" required></textarea>

              <input type="submit" value="Submit Announcement" />
            </form>
          </main>
        </div>
      </div>
    </div>
  );
}

export default InstructorAnnouncement;
