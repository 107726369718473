import React from 'react';
import { Link } from 'react-router-dom';
import './Qao.css'
import Header from '../Coordinator/Header'

class QualityAssuranceOfficer extends React.Component {
    render() {
        return (
            <div className="main">
              < Header authPage='qa' />

                <div className="col">
                    <Link to='/Piechart'>
                        <div className="Tab Tab5">
                            <div className="overlay-text">CSE-5335-003</div>
                        </div>
                    </Link>
                    <Link to='/Piechart'>
                        <div className="Tab Tab11">
                            <div className="overlay-text">CSE-5334-002</div>
                        </div>
                    </Link>
                    <Link to='/Piechart'>
                        <div className="Tab Tab12">
                            <div className="overlay-text">CSE-5315-001</div>
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
}

export default QualityAssuranceOfficer;