// src/App.js
import React, { useState } from 'react';
import axios from 'axios';
import NavBar from './navbar';

function ImageGen() {

  const [prompt, setPrompt] = useState('');
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handlePromptChange = (event) => {
    setPrompt(event.target.value);
  };

  const generateImage = async () => {
    setIsLoading(true);
    try {
      // Replace 'YOUR_API_KEY_HERE' with your actual OpenAI API key
      const headers = {
        Authorization: `Bearer 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`,
      };

      const response = await axios.post(
        'https://api.openai.com/v1/images/generations',
        {
          prompt: prompt,
          n: 1, // Number of images to generate
          size: '1024x1024' // Size of the image
        },
        { headers }
      );

      setImages(response.data.data); // Adjust according to the actual response structure
    } catch (error) {
      console.error('Error generating image:', error);
      alert('Failed to generate image: ' + error.message);
    }
    setIsLoading(false);
  };

  return (
    <>
    < NavBar />
    <div>
      <input type="text" value={prompt} onChange={handlePromptChange} placeholder="Enter a prompt" />
      <button onClick={generateImage} disabled={isLoading}>
        {isLoading ? 'Generating...' : 'Generate Image'}
      </button>
      <div>
        {images.map((image, index) => (
          <img key={index} src={image.url} alt={`Generated for prompt: ${prompt}`} />
        ))}
      </div>
    </div>
    </>
  );
}

export default ImageGen;
