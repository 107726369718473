import React from 'react'
import { Link, useNavigate } from "react-router-dom";
/*import '../lrstyle.css';*/
import '../chatbotstyle.css';
import '../student_courses_dashboard/student-courses-dashboard.css';
import logo from '../images/logo.png';
import Header from '../../Coordinator/Header';

export default function StudentCoursesDashboard() {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    const userEmail = userDetails && userDetails.email;
    const navigate = useNavigate(); // Using React Router's useNavigate

    const handleLogout = () => {
        sessionStorage.removeItem('userDetails'); // Clear the userDetails
        navigate('/Login'); // Redirect to the Login page using navigate
    }
    return (
        <>
            < Header authPage='student' />

            <div class="col">
                <Link to='/student/student-courses1'>
                    <div className="Tab Tab11">
                        <div className="overlay-text">
                            CSE-5335-003
                        </div>
                    </div>
                </Link>
                <Link to='/student/student-courses1'>
                    <div className="Tab Tab22">
                        <div className="overlay-text">
                            CSE-5334-002
                        </div>
                    </div>
                </Link>
                <Link to='/student/student-courses1'>
                    <div className="Tab Tab33">
                        <div className="overlay-text">
                            CSE-5315-001
                        </div>
                    </div>
                </Link>

                <div className="chat-container">
                    <div className="chat-header">
                        Chat Room
                    </div>
                    <div className="chat-messages">
                        <div className="message">User1: Hello!</div>
                        <div className="message">User2: Hi there!</div>
                    </div>
                    <div className="chat-input">
                        <input type="text" placeholder="Type your message..."></input>
                        <button>Send</button>
                    </div>
                </div>
            </div>
        </>
    );
}
