import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import '../chatbotstyle.css';
import '../student_courses1/student-courses1.css';
import logo from '../images/logo.png';
import wdmsyallabus from '../assests/wdmsyallabus.pdf'
import wdmmodule from '../assests/wdmmodule.pdf'
import Header from '../../Coordinator/Header';

export default function StudentCourses1() {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    const userEmail = userDetails && userDetails.email;
    const navigate = useNavigate(); // Using React Router's useNavigate

    const handleLogout = () => {
        sessionStorage.removeItem('userDetails'); // Clear the userDetails
        navigate('/Login'); // Redirect to the Login page using navigate
    }
    return (
        <>
            < Header authPage='student' />

            <div className="col">
                <div className="Tab Tab1">
                    <div className="content-wrapper">
                        <a href={wdmsyallabus} rel="noreferrer" target="_blank"> Syallabus </a>
                    </div>
                </div>
                <div className="Tab Tab2">
                    <div className="content-wrapper">
                        <a href={wdmmodule} rel="noreferrer" target="_blank"> Modules </a>
                    </div>
                </div>
                <Link to='/student/student-exams-dashboard'>
                    <div className="Tab Tab3">
                        <div className="content-wrapper">
                            Exams
                        </div>
                    </div>
                </Link>
                <Link to='/student/student-book-appointment'>
                    <div className="Tab Tab10">
                        <div className="content-wrapper">
                            Book Appointment
                        </div>
                    </div>
                </Link>

            </div>

            <div className="chat-container">
                <div className="chat-header">
                    Chat Room
                </div>
                <div className="chat-messages">
                    <div className="message">User1: Hello!</div>
                    <div className="message">User2: Hi there!</div>
                </div>
                <div className="chat-input">
                    <input type="text" placeholder="Type your message..."></input>
                    <button>Send</button>
                </div>
            </div>

        </>
    )

}
