import '../Coordinator/defaultStyle.css';
import React, { useState } from 'react';
import NavBar from './navbar';
import API_BASE_URL from '../config';
import axios from 'axios';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isMessageVisible, setIsMessageVisible] = useState(false);
    const [nameError, setNameError] = useState('');
    const [messageError, setMessageError] = useState('');

    const validateName = (name) => {
        const nameRegex = /^[a-zA-Z\s]*$/;
        return nameRegex.test(name);
    };

    const validateMessageLength = (message) => {
        return message.length > 50;
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
        // Clear name error when the user starts typing
        setNameError('');
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
        // Clear message error when the user starts typing
        setMessageError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // You can perform any necessary form submission logic here


        // Validate the name
        if (!validateName(name)) {
            setNameError('Please enter a valid name (letters and spaces only).');
            return;
        }

        // Validate the message length
        if (!validateMessageLength(message)) {
            setMessageError('Please enter a message longer than 50 characters.');
            return;
        }

        // Clear any previous name error
        setNameError('');

        // form submission logic here
        try {
            const response = await fetch(`${API_BASE_URL}/api/contact_us.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name,
                    email,
                    message,
                }),
            });
        } catch (e) { /** */ }


        // Display a success message
        setMessage('Your message has been submitted!');
        setIsMessageVisible(true);

        // Reset the form (optional)
        e.target.reset();

        // Hide the message after a few seconds (optional)
        setTimeout(() => {
            setIsMessageVisible(false);
        }, 3000);
    };


    return (
        <>
            < NavBar />

            <main className="container">
                <h2>Contact Information</h2>

                <p>If you have any questions or inquiries, please feel free to contact us using the form below:</p>

                <form onSubmit={handleSubmit}>
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={handleNameChange}
                        required
                    />
                    {nameError && <div className="error-message">{nameError}</div>}

                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />

                    <label htmlFor="message">Message:</label>
                    <textarea
                        id="message"
                        name="message"
                        value={message}
                        onChange={handleMessageChange}
                        rows="4"
                        required
                    ></textarea>
                    {messageError && <div className="error-message">{messageError}</div>}

                    <br></br>
                    <br></br>

                    <button type="submit">Submit</button>

                    {isMessageVisible && <div className="success-message">{message}</div>}
                </form>
            </main>
        </>
    );
};

export default Contact;
