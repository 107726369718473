import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../config';
import logo from '../assets/images/logo.png';
import Header from '../Coordinator/Header'

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = { contacts: [] };
    this.headers = [
      { key: 'policyID', label: 'Policy ID' },
      { key: 'policyName', label: 'Policy Name' },
    ];
    this.deleteContact = this.deleteContact.bind(this);
  }

  componentDidMount() {
    const url = `${API_BASE_URL}/api/policyy.php`;
    axios.get(url)
      .then(response => response.data)
      .then((data) => {
        this.setState({ contacts: data });
        console.log(this.state.contacts);
      });
  }

  deleteContact(id, event) {
    event.preventDefault();
    if (window.confirm("Are you sure you want to delete?")) {
      axios({
        method: 'post',
        url: `${API_BASE_URL}/api/policyy.php/?delete=${id}`
      })
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.status === 200) {
            alert("Website deleted successfully");
          }
        })
        .catch(function (response) {
          // handle error
          console.log(response);
        });
    }
  }

  render() {
    return (
      <div className="main">
          < Header authPage='qa' />

        <div></div>
        <div className="container">
          <h1>Policies</h1>
          <p>
            <Link to="/create" className="update">New Policies</Link>
          </p>
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                {this.headers.map(function (h) {
                  return (
                    <th key={h.key}>{h.label}</th>
                  );
                })}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.contacts.map(function (item, key) {
                return (
                  <tr key={key}>
                    <td>{item.policyID}</td>
                    <td>{item.policyName}</td>
                    <td>
                      <Link to={`/update/${item.policyID}`} className="update">Edit</Link>
                      <Link to="#" onClick={this.deleteContact.bind(this, item.policyID)} className="delete">Delete</Link>
                    </td>
                  </tr>
                );
              }.bind(this))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Contact;
