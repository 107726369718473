import React, { useEffect, useState } from 'react';
import styles from './manageUser.module.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import API_BASE_URL from '../config';
import Header from '../Coordinator/Header'

function ManageUsers() {
    const [users, setUsers] = useState([]);
    const [roleFilter, setRoleFilter] = useState('all');
    // const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        // Fetch user data from the server when the component mounts
        axios.get(`${API_BASE_URL}/api/getAllUsers.php`)
            .then(response => {
                console.log(response.data);
                setUsers(response.data);
            })
            .catch(error => {
                console.error('Error fetching user data:', error);
            });
    }, []);

    // Handle filter change
    const handleFilterChange = (event) => {
        setRoleFilter(event.target.value);
    };

    // Handle delete button click
    const handleDelete = (user) => {
        // Send a request to delete the user
        axios.post(`${API_BASE_URL}/api/deleteUser.php`, { email: user.email }, {
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then(response => {
            // Update the user list after successful deletion
            setUsers(users.filter(u => u.email !== user.email));
        })
            .catch(error => {
                console.error('Error deleting user:', error);
            });
    };

    return (
        <>
            < Header authPage='admin' />


            <div className="col">
                <div className={styles.manageUsersContainer}>
                    <h2>Manage Users</h2>

                    <div className={styles.filterContainer}>
                        <label htmlFor="role">Filter by Role:</label>
                        <select id="role" onChange={handleFilterChange} value={roleFilter}>
                            <option value="all">All</option>
                            <option value="student">student</option>
                            <option value="instructor">Instructor</option>
                            <option value="qa">QAO</option>
                            <option value="programcoordinator">Program Coordinator</option>
                        </select>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users
                                .filter(user => roleFilter === 'all' || user.usertype === roleFilter)
                                .map((user, index) => (
                                    <tr key={index}>
                                        <td>{user.firstname}</td>
                                        <td>{user.email}</td>
                                        <td>{user.usertype}</td>
                                        <td>
                                            <button
                                                className={`${styles.btn} ${styles.editBtn}`}
                                            >
                                                <Link to={`/AdminEditProfile/${user.email}`} style={{ color: 'white' }}>Edit</Link>
                                            </button>
                                        </td>
                                        <td>
                                            <button
                                                className={`${styles.btn} ${styles.deleteBtn}`}
                                                onClick={() => handleDelete(user)}
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
            </>

    );
}

export default ManageUsers;
