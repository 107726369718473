import React from 'react';
import { Link } from 'react-router-dom';
import './performanceStyle.css';
import Header from './Header';

function PerformanceMonitoring() {
    return (
        <>
            < Header authPage='programcoordinator' />

            <div className='performance'>
                <header>
                    <h1>Performance Monitoring</h1>
                </header>

                <div className="button-container">
                    <Link to="/StudentsPerformance" className="button">
                        View Students Performance
                    </Link>
                    <Link to="/CoursePerformance" className="button">
                        View Courses Performance
                    </Link>
                </div>
            </div>
        </>
    );
}

export default PerformanceMonitoring;
