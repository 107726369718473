// import React, { useEffect } from 'react';
import Header from "../Coordinator/Header";
import styles from "./adminDashboard.module.css";
import chatbox from "./adminChatBox.module.css";
import { Link } from "react-router-dom";

const MyComponent = () => {
  return (
    <>
      <Header authPage="admin" />

      <div className={styles.col}>
        <Link to="/AdminProfile">
          <div className={styles.Tab + " " + styles.Tab1}>
            <div className={styles.overlayText}>Admin Profile</div>
          </div>
        </Link>
        <Link to="/UserPermission">
          <div className={styles.Tab + " " + styles.Tab2}>
            <div className={styles.overlayText}>User Permissions</div>
          </div>
        </Link>
        <Link to="/ManageUsers">
          <div className={styles.Tab + " " + styles.Tab3}>
            <div className={styles.overlayText}>Manage Users</div>
          </div>
        </Link>
        <Link to="/MonitorActivity">
          <div className={styles.Tab + " " + styles.Tab4}>
            <div className={styles.overlayText}>Monitor Activity</div>
          </div>
        </Link>
        <Link to="/chat">
          <div className={styles.Tab + " " + styles.Tab5}>
            <div className={styles.overlayText}>Chat</div>
          </div>
        </Link>
      </div>
      {/* <div className={chatbox.chatContainer}>
        <div className={chatbox.chatHeader}>Admin Chat</div>
        <div className={chatbox.chatMessages}>
          <div className={chatbox.message}>User1: Hello, admin!</div>
          <div className={chatbox.message}>Admin: How can I help you?</div>
        </div>
        <div className={chatbox.chatInput}>
          <input type="text" placeholder="Type your message..." />
          <button>Send</button>
        </div>
      </div> */}
    </>
  );
};

export default MyComponent;
