import React, { useState, useEffect } from 'react';
import Header from './Header';
// import { useNavigate } from "react-router-dom";
import './defaultStyle.css';
import API_BASE_URL from '../config';

function CoordinatorProfile() {
  const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  const userEmail = userDetails && userDetails.email;
  const [profileData, setProfileData] = useState({});
  // const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);

  // console.log(`profileData - ${JSON.stringify(profileData)}`);

  const [editedData, setEditedData] = useState({
    firstname: profileData.firstname || '',
    lastname: profileData.lastname || '',
    email: profileData.email || '',
    dob: profileData.dob || '',
    mobile: profileData.mobile || ''
  });

  // console.log(`editedData - ${JSON.stringify(editedData)}`);

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setEditedData(prevData => ({
      ...prevData,
      [id]: value
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    setProfileData(editedData);

    console.log(`profileDataah - ${JSON.stringify(profileData)}`);

    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    const userEmail = userDetails && userDetails.email;

    const newProfileData = {
      firstname: editedData.firstname || profileData.firstname,
      // lastname: editedData.lastname || profileData.lastname,
      email: userEmail,
      dob: editedData.dob || profileData.dob,
      mobile: editedData.mobile || profileData.mobile
    };

    // Send a POST request to your PHP backend
    const response = await fetch(`${API_BASE_URL}/api/update-profile.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newProfileData),
    });

    // console.log(`Response ${JSON.stringify(response)}`);

    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    const userEmail = userDetails && userDetails.email;

    if (userEmail) {
      fetch(`${API_BASE_URL}/api/profile.php`, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-type": "application/json"
        },
        body: JSON.stringify({ email: userEmail })
      })
        .then(response => response.json())
        .then(data => {
          if (!data.error) {
            setProfileData(data);
          } else {
            console.error(data.error);
          }
        })
        .catch(error => console.error(error));
    }
  }, []);

  // const handleLogout = () => {
  //     sessionStorage.removeItem('userDetails'); // Clear the userDetails
  //     navigate('/Login'); // Redirect to the Login page using navigate
  // }

  return (
    <>
            < Header authPage='programcoordinator' />

      <div className="coordinator-profile">
        <h2>Program Coordinator Profile</h2>

        <input type="checkbox" id="edit-mode" hidden></input>
        <div className="s-profile-info">
          <label>Name:</label>
          {isEditing ? (
            <input
              id="firstname"
              value={editedData.firstname}
              onChange={handleInputChange}
              placeholder="First Name"
            />
          ) : (
            <span>{`${profileData.firstname} ${profileData.lastname}`}</span>
          )}

          <label>Email:</label>
          {isEditing ? (
            <input
              id="email"
              value={editedData.email}
              onChange={handleInputChange}
              placeholder="Email"
            />
          ) : (
            <span>{profileData.email}</span>
          )}
          <label>Date of birth:</label>
          {isEditing ? (
            <input
              id="dob"
              value={editedData.dob}
              onChange={handleInputChange}
              placeholder="Dob"
            />
          ) : (
            <span>{profileData.dob}</span>
          )}

          <label>Phone number:</label>
          {isEditing ? (
            <input
              id="mobile"
              value={editedData.mobile}
              onChange={handleInputChange}
              placeholder="Mobile"
            />
          ) : (
            <span>{profileData.mobile}</span>
          )}

          <div className="btn-container">
            {!isEditing && <button onClick={handleEdit} className="s-btn">Edit</button>}
            {isEditing && (
              <>
                <button onClick={handleSave} className="s-btn">Save</button>
                <button onClick={handleCancel} className="s-btn">Cancel</button>
              </>
            )}
          </div>
        </div>
      </div>
    </>

    // <>
    //   <Header />
    //   <div className="coordinator-profile">
    // <h2>Program Coordinator Profile</h2>

    //     <input type="checkbox" id="edit-mode" hidden checked={editMode} onChange={() => { }} />

    //     <div className="profile-info">
    //       <label htmlFor="name">Name:</label>
    //       {editMode ? (
    //         <input
    //           className="edit-field"
    //           type="text"
    //           id="name-input"
    //           value={name}
    //           onChange={(e) => setName(e.target.value)}
    //         />
    //       ) : (
    //         <span className="text-field" id="name-text">
    //           {name}
    //         </span>
    //       )}

    //       <label htmlFor="email">Email:</label>
    //       {editMode ? (
    //         <input
    //           className="edit-field"
    //           type="text"
    //           id="email-input"
    //           value={email}
    //           onChange={(e) => setEmail(e.target.value)}
    //         />
    //       ) : (
    //         <span className="text-field" id="email-text">
    //           {email}
    //         </span>
    //       )}

    //       <label htmlFor="dob">Date of birth:</label>
    //       {editMode ? (
    //         <input
    //           className="edit-field"
    //           type="text"
    //           id="dob-input"
    //           value={dob}
    //           onChange={(e) => setDob(e.target.value)}
    //         />
    //       ) : (
    //         <span className="text-field" id="dob-text">
    //           {dob}
    //         </span>
    //       )}

    //       <label htmlFor="pno">Phone no:</label>
    //       {editMode ? (
    //         <input
    //           className="edit-field"
    //           type="text"
    //           id="pno-input"
    //           value={phone}
    //           onChange={(e) => setPhone(e.target.value)}
    //         />
    //       ) : (
    //         <span className="text-field" id="pno-text">
    //           {phone}
    //         </span>
    //       )}

    //       <div className="btn-container">
    //         {editMode ? (
    //           <label htmlFor="edit-mode" id="save-btn" className="btn" onClick={handleSave}>
    //             Save
    //           </label>
    //         ) : (
    //           <label htmlFor="edit-mode" id="edit-btn" className="btn" onClick={handleEdit}>
    //             Edit
    //           </label>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    // </>
  );
}

export default CoordinatorProfile;