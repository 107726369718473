import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import logo from '../Student/images/logo.png';
import API_BASE_URL from '../config';
import Header from '../Coordinator/Header';
 
class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = { contacts: [] };
    this.headers = [
      { key: 'id', label: 'id' },
      { key: 'ExamName', label: 'Exam Name' }, // Updated key and label
      { key: 'Examid', label: 'Examid' }, // Updated key and label
      { key: 'CourseID', label: 'CourseID' }, // Updated key and label
      { key: 'Duedate', label: 'Due Date' },
      { key: 'Marks', label: 'Score' }, // Updated key and label
      
    ];
    this.deleteContact = this.deleteContact.bind(this);
  }

  componentDidMount() {
    const url = `${API_BASE_URL}/api/exams.php`;
    axios.get(url)
      .then(response => response.data)
      .then((data) => {
        this.setState({ contacts: data });
        console.log(this.state.contacts);
      });
  }

  deleteContact(id, event) {
    event.preventDefault();
    if (window.confirm("Are you sure you want to delete?")) {
      axios({
        method: 'post',
        url: `${API_BASE_URL}/api/exams.php/?delete=${id}`
      })
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.status === 200) {
            alert("Exam deleted successfully");
          }
        })
        .catch(function (response) {
          // handle error
          console.log(response);
        });
    }
  }

  render() {
    return (
      <div className="main">
          < Header authPage='instructor' />

      <div className="container">
        <h1>Exams</h1>
        <p>
          <Link to="/examsnew" className="update">Add Exam</Link>
        </p>
        <table className="PreviousPolicies">
          <thead>
            <tr>
              {this.headers.map(function (h) {
                return (
                  <th key={h.key}>{h.label}</th>
                );
              })}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.contacts.map(function (item, key) {
              return (
                <tr key={key}>
                  <td>{item.id}</td>
                  <td>{item.ExamName}</td>
                  <td>{item.Examid}</td>
                  <td>{item.CourseID}</td>
                  <td>{item.Duedate}</td>
                  <td>{item.Marks}</td>
                  <td>
                    
                    <Link to={`/updateexams/${item.id}`} className="update">Edit</Link>
                    
                    </td>
                    <td>
                      <Link to="#" onClick={this.deleteContact.bind(this, item.id)} className="delete">Delete</Link>
                    
                    </td>
                </tr>
              );
            }.bind(this))}
          </tbody>
        </table>
      </div>
      </div>
    );
  }
}

export default Contact;
