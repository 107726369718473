const is_localhost = window.location.hostname === "localhost";
const is_laravel = false;
const API_BASE_URL = is_localhost
  ? `http://localhost:${is_laravel ? "8000" : "8888"}`
  : "https://sxd5959.uta.cloud"; // Apache Server
//const CHAT_BASE_URL = is_localhost
 // ? "https://localhost:8080"
 // : "https://fxa3967.uta.cloud"; // chat server


export default API_BASE_URL;
