import React, { useState } from "react";
import styles from "./navbar.module.css";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";

const Header = () => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Set this based on your authentication logic

  const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
  const userEmail = userDetails && userDetails.email;
  const userType = userDetails && userDetails.usertype;
  // const navigate = useNavigate(); // Using React Router's useNavigate

  const handleLogin = () => {
    // Simulate a successful login
    setIsLoggedIn(true);
  };

  if (userEmail & !isLoggedIn) {
    handleLogin();
  }

  const handleLogout = () => {
    // Perform logout actions here, e.g., clear user data, tokens, etc.
    sessionStorage.removeItem("userDetails"); // Clear the userDetails
    setIsLoggedIn(false);
  };

  return (
    <div className={styles.navbar}>
      <div className={styles.icon}>
        <h2 className={styles.logo}>
          <Link to="/">
            <img src={logo} alt="Edumetric" />
          </Link>
        </h2>
      </div>
      <div className={styles.headermenu}>
        <ul>
          {location.pathname !== "/" && (
            <li>
              {userType && userType === "student" && (
                <Link to="/Student">Home</Link>
              )}
              {userType && userType === "instructor" && (
                <Link to="/Instructors">Home</Link>
              )}
              {userType && userType === "admin" && (
                <Link to="/Admin">Home</Link>
              )}
              {userType && userType === "programcoordinator" && (
                <Link to="/Coordinator">Home</Link>
              )}
              {userType && userType === "qa" && <Link to="/QA">Home</Link>}
              {!userType && <Link to="/">Home</Link>}
            </li>
          )}
          {location.pathname !== "/About" && (
            <li>
              <Link to="/About">About</Link>
            </li>
          )}
          {location.pathname !== "/Services" && (
            <li>
              <Link to="/Services">Services</Link>
            </li>
          )}
          {location.pathname !== "/Contact" && (
            <li>
              <Link to="/Contact">Contact</Link>
            </li>
          )}
          {
            <li>
              <Link to="http://sxg1200.uta.cloud" target="__blank">
                Blog
              </Link>
            </li>
          }
          {!userEmail && location.pathname !== "/Register" && (
            <li>
              <Link to="/Register">Register</Link>
            </li>
          )}
          {!userEmail && location.pathname !== "/Login" && (
            <li>
              <Link to="/Login">Login</Link>
            </li>
          )}
          {!userEmail && location.pathname !== "/ImageGen" && (
            <li>
              <Link to="/ImageGen">Image Generator</Link>
            </li>
          )}

          {userEmail && <li>Logged in as: {userEmail}</li>}

          {userEmail && (
            <li onClick={handleLogout}>
              <Link>Logout</Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Header;