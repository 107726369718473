import { React }  from 'react'
import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
import '../chatbotstyle.css';
import '../student_book_appointment/student-book-appointment.css';
import logo from '../images/logo.png';
import API_BASE_URL from '../../config';
import Header from '../../Coordinator/Header';

export default function StudentBookAppointment() {
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submission

        const form = event.target;
        const formData = new FormData(form);

        fetch(`${API_BASE_URL}/api/appointment.php`, {
            method: 'POST',
            body: formData
        })
        .then(response => {
            if (response.ok) {
                return response.json(); // or return response.text() if the response is not in JSON format
            }
            throw new Error('Network response was not ok.');
        })
        .then(data => {
            alert('Appointment has been booked');
            // You can navigate to another route or perform other actions here
            // navigate('/appointment-success');
        })
        .catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
        });
    };

    return (
        <>
            < Header authPage='student' />

            <div className="s-appointment-container">
                <h1 style={{ textAlign: 'center' }}>Book an Appointment</h1>

                <form onSubmit={handleSubmit}>
                    <div class="form-groups">
                        <label>Student Name:</label>
                        <input type="text" id="student-name" name="student-name" required />
                    
                    
                        <label htmlFor="student-id">Student ID:</label>
                        <input type="text" id="student-id" name="student-id" required />
                    
                        <label htmlFor="student-id">Instructor ID:</label>
                        <input type="text" id="instructor-id" name="instructor-id" required />
                    
                        <label htmlFor="date">Date:</label>
                        <input type="date" id="date" name="date" required />
                    
                        <label htmlFor="time">Time:</label>
                        <input type="time" id="time" name="time" required />
                    
                        <label htmlFor="reason">Reason for Appointment:</label>
                        <textarea id="reason" name="reason" rows="4" required></textarea>
                    </div>
                    <div className="s-input-group">
                    <button type="submit" className="s-btn" name="book">Book</button>
                </div>
                </form>
                
            </div>
            <div className="s-chat-container">
                <div className="s-chat-header">
                    Chat Room
                </div>
                <div className="s-chat-messages">
                    <div className="s-message">User1: Hello!</div>
                    <div className="s-message">User2: Hi there!</div>
                </div>
                <div className="s-chat-input">
                    <input type="text" placeholder="Type your message..." />
                    <button>Send</button>
                </div>
            </div>
        </>
    );
}
