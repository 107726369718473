import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../lrstyle.css';
import '../chatbotstyle.css';
import '../student_profile/student-profile.css';
import logo from '../images/logo.png';
import API_BASE_URL from '../../config';
import Header from '../../Coordinator/Header';

export default function StudentProfile() {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    const userEmail = userDetails && userDetails.email;
    const [profileData, setProfileData] = useState({});
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState({
        firstname: profileData.firstname || '',
        lastname: profileData.lastname || '',
        email: profileData.email || '',
        dob: profileData.dob || '',
        mobile: profileData.mobile || ''
    });
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setEditedData(prevData => ({
            ...prevData,
            [id]: value
        }));
    };

    const handleEdit = () => {
        setIsEditing(true);

    };

    const handleSave = () => {
        // Here, you would typically make an API call to save the changes
        // For simplicity, we'll update the local profile data
        setProfileData(editedData);
        setIsEditing(false);
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    useEffect(() => {
        const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
        const userEmail = userDetails && userDetails.email;

        if (userEmail) {
            fetch(`${API_BASE_URL}/api/profile.php`, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-type": "application/json"
                },
                body: JSON.stringify({ email: userEmail })
            })
                .then(response => response.json())
                .then(data => {
                    if (!data.error) {
                        setProfileData(data);
                    } else {
                        console.error(data.error);
                    }
                })
                .catch(error => console.error(error));
        }
    }, []);
    useEffect(() => {
        setEditedData({
            firstname: profileData.firstname || '',
            lastname: profileData.lastname || '',
            email: profileData.email || '',
            dob: profileData.dob || '',
            mobile: profileData.mobile || ''
        });
    }, [profileData]);
    const handleLogout = () => {
        sessionStorage.removeItem('userDetails'); // Clear the userDetails
        navigate('/Login'); // Redirect to the Login page using navigate
    }
    return (
        <>
            < Header authPage='student' />

            <div className="s-student-profile">
                <h2>Student Profile</h2>
                <input type="checkbox" id="edit-mode" hidden></input>
                <div class="s-profile-info">
                    <label>Name:</label>
                    {isEditing ? (
                        <input
                            id="firstname"
                            value={editedData.firstname}
                            onChange={handleInputChange}
                            placeholder="First Name"
                        />
                    ) : (
                        <span>{`${profileData.firstname} ${profileData.lastname}`}</span>
                    )}

                    <label>Email:</label>
                    {isEditing ? (
                        <input
                            id="email"
                            value={editedData.email}
                            onChange={handleInputChange}
                            placeholder="Email"
                        />
                    ) : (
                        <span>{profileData.email}</span>
                    )}
                    <label>Date of birth:</label>
                    {isEditing ? (
                        <input
                            id="dob"
                            value={editedData.dob}
                            onChange={handleInputChange}
                            placeholder="Dob"
                        />
                    ) : (
                        <span>{profileData.dob}</span>
                    )}

                    <label>Phone number:</label>
                    {isEditing ? (
                        <input
                            id="mobile"
                            value={editedData.mobile}
                            onChange={handleInputChange}
                            placeholder="Mobile"
                        />
                    ) : (
                        <span>{profileData.mobile}</span>
                    )}





                    <div className="s-btn-container">
                        {!isEditing && <button onClick={handleEdit} className="s-btn">Edit</button>}
                        {isEditing && (
                            <>
                                <button onClick={handleSave} className="s-btn">Save</button>
                                <button onClick={handleCancel} className="s-btn">Cancel</button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
