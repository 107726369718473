import { React, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../student_dashboard/student-dashboard.css";
import "../chatbotstyle.css";
import logo from "../images/logo.png";
import Header from "../../Coordinator/Header";

export default function StudentDashboard() {
  return (
    <>
      <Header authPage="student" />

      <div className="col">
        <Link to="/student/student-profile">
          <div className="Tab Tab15">
            <div className="overlay-text">Profile</div>
          </div>
        </Link>
        <Link to="/student/student-courses-dashboard">
          <div className="Tab Tab16">
            <div className="overlay-text">Courses</div>
          </div>
        </Link>
        <Link to="/student/student-reports">
          <div className="Tab Tab17">
            <div className="overlay-text">Reports</div>
          </div>
        </Link>
        <Link to="/student/student-discussions">
          <div className="Tab Tab18">
            <div className="overlay-text">Discussions</div>
          </div>
        </Link>
        <Link to="/chat">
          <div className="Tab Tab19">
            <div className="overlay-text">Chat</div>
          </div>
        </Link>
        {/* <div className="chat-container">
                    <div className="chat-header">
                        Chat Room
                    </div>
                    <div className="chat-messages">
                        <div className="message">User1: Hello!</div>
                        <div className="message">User2: Hi there!</div>
                    </div>
                    <div className="chat-input">
                        <input type="text" placeholder="Type your message..."></input>
                        <button>Send</button>
                    </div>
                </div> */}
      </div>
    </>
  );
}
