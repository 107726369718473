import React from 'react';
import { Link } from "react-router-dom";
import NavBar from './commonComponents/navbar';
import './App.css'
import home from "./assets/images/Home.jpg";
// import Header from './Coordinator/Header'

const HomePage = () => {
  return (
    <div>
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </head>

      < NavBar />
      <div className="home-tiles">
        <p>The purpose of the graduate program in computer science is to facilitate the student's continued
          professionally and scholarly development. The Master of Science Programs are designed to extend
          students' knowledge and emphasize a particular area of concentration.
        </p>

        <div className="home-form">
          <Link to="/Student">
            <button className="btnn">STUDENT</button>
          </Link>
          <Link to="/Instructors">
            <button className="btnn">INSTRUCTOR</button>
          </Link>
          <Link to="/Admin">
            <button className="btnn">ADMIN</button>
          </Link>
          <Link to="/QA">
            <button className="btnn">QA-OFFICER</button>
          </Link>
          <Link to="/Coordinator">
            <button className="btnn">PROGRAM COORDINATOR</button>
          </Link>
        </div>

      <img className='homeLogo' src={home} alt="Home" />

      </div>


      {/* <div className="colm"> */}
      {/* </div> */}

    </div>
  );
};

export default HomePage;
