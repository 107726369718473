import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../config';
import Header from '../Coordinator/Header';

const CreateExam = () => {
  const [examData, setExamData] = useState({
    ExamName: '',
    Examid: '',
    CourseID: '',
    Duedate: '',
    Marks: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setExamData({ ...examData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('ExamName', examData.ExamName);
    formData.append('Examid', examData.Examid);
    formData.append('CourseID', examData.CourseID);
    formData.append('Duedate', examData.Duedate);
    formData.append('Marks', examData.Marks);

    axios({
      method: 'post',
      url: `${API_BASE_URL}/api/exams.php`, // Change the URL to the exam API
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then(function (response) {
        // Handle success
        console.log(response);
        alert('New Exam Successfully Added.');
      })
      .catch(function (response) {
        // Handle error
        console.error(response);
      });
  };

  return (
    <div className="main">
      <div className="navbar">
        <div className="icon">
          <h2 className="logo">
            <img src="logo.png" alt="Edumetric" />
          </h2>
        </div>
        <div className="headermenu">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/chat">Messages</a>
            </li>
            <li>
              <a href="/Login">Signout</a>
            </li>
          </ul>
        </div>
      </div>
      <div>
      </div>
      <div className="container">
        <h1 className="page-header text-center">Add New Exam</h1>
        <Link to="/exams" className="update">
          Home
        </Link>
        <div className="col-md-12">
          <div className="panel panel-primary">
            <div className="panel-body">
              <form onSubmit={handleSubmit}>
                <label>Exam Name</label>
                <input
                  type="text"
                  name="ExamName"
                  className="form-control"
                  value={examData.ExamName}
                  onChange={handleChange}
                />

                <label>Exam ID</label>
                <input
                  type="text"
                  name="Examid"
                  className="form-control"
                  value={examData.Examid}
                  onChange={handleChange}
                />

                <label>Course ID</label>
                <input
                  type="text"
                  name="CourseID"
                  className="form-control"
                  value={examData.CourseID}
                  onChange={handleChange}
                />

                <label>Due Date</label>
                <input
                  type="text"
                  name="Duedate"
                  className="form-control"
                  value={examData.Duedate}
                  onChange={handleChange}
                />

                <label>Marks</label>
                <input
                  type="text"
                  name="Marks"
                  className="form-control"
                  value={examData.Marks}
                  onChange={handleChange}
                />
                <br />
                <input type="submit" className="update" value="Create Exam" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateExam;
