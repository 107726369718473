import React, { useEffect, useState } from 'react';
import styles from './monitorActivity.module.css';
import './defaultStyle.css';
import axios from 'axios';
import API_BASE_URL from '../config';
import Header from '../Coordinator/Header'

function MonitorActivity() {
    const [activityData, setActivityData] = useState([]);

    useEffect(() => {
        // Fetch user login activity data from the server when the component mounts
        axios.get(`${API_BASE_URL}/api/monitor.php`)
            .then(response => {
                console.log(response.data);
                setActivityData(response.data);
            })
            .catch(error => {
                console.error('Error fetching user login activity data:', error);
            });
    }, []);

    return (
        <>
            < Header authPage='admin' />

            <div className="col">
                <div className={styles.monitorActivityContainer}>
                    <h2>Monitor User Activity</h2>

                    <table>
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Login Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {activityData.map((activity, index) => (
                                <tr key={index}>
                                    <td>{activity.email}</td>
                                    <td>{activity.role}</td>
                                    <td>{activity.login}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>

    );
}

export default MonitorActivity;
