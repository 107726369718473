import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../config';
import Header from '../Coordinator/Header'

const Update = () => {
  const { id } = useParams(); // This will get the 'id' parameter from the URL

  const [policyID, setPolicyID] = useState('');
  const [policyName, setPolicyName] = useState('');

  useEffect(() => {
    // Fetch policy data when the component mounts
    axios
      .get(`${API_BASE_URL}/api/policyy.php/?id=${id}`)
      .then((response) => response.data)
      .then((data) => {
        setPolicyID(data.policyID);
        setPolicyName(data.policyName);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [id]); // Make sure to include 'id' in the dependency array

  const handlePolicyIDChange = (event) => {
    setPolicyID(event.target.value);
  };

  const handlePolicyNameChange = (event) => {
    setPolicyName(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let formData = new FormData();
    formData.append('policyID', policyID);
    formData.append('policyName', policyName);

    axios
      .post(`${API_BASE_URL}/api/policyy.php/?id=${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(function (response) {
        if (response.status === 200) {
          alert('Policy updated successfully.');
          // Redirect to the home page after the update
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  return (
    <div className="container">
      <h1 className="page-header text-center">Update Policy</h1>
      <Link to="/" className="btn btn-primary btn-xs">
        Home
      </Link>
      <div className="col-md-12">
        <div className="panel panel-primary">
          <div className="panel-body">
            <form onSubmit={handleSubmit}>
              <input type="hidden" name="id" value={id} />
              <label>Policy ID:</label>
              <input
                type="text"
                name="policyID"
                className="form-control"
                value={policyID}
                onChange={handlePolicyIDChange}
              />

              <label>Policy Name:</label>
              <input
                type="text"
                name="policyName"
                className="form-control"
                value={policyName}
                onChange={handlePolicyNameChange}
              />
              <br />
              <input type="submit" className="btn btn-primary btn-block" value="Update" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Update;
