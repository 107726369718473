import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';

function Conversations() {
    return (
        <div>
            < Header authPage='programcoordinator' />

            <header>
                <h1>All Conversations</h1>
            </header>

            <br></br>

            <Link to="/ChatAdmin" className="button">
                Chat with Admins
            </Link>
            <Link to="/ChatStudent" className="button">
                Chat with Students
            </Link>
            <Link to="/ChatInstructor" className="button">
                Chat with Instructors
            </Link>
            <Link to="/ChatQA" className="button">
                Chat with QAs
            </Link>
        </div>
    );
}

export default Conversations;
