import React from "react";
import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
import { useState, useEffect } from "react";
import "../../commonComponents/login/loginStyle.css";
import NavBar from "../navbar";
import "../../Student/lrstyle.css";
import API_BASE_URL from "../../config";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [usertype, setUsertype] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    let login = localStorage.getItem("login");
    if (login) {
      navigate("/Login");
    }
    let loginStatus = localStorage.getItem("loginStatus");
    if (loginStatus) {
      setError(loginStatus);
      setTimeout(function () {
        localStorage.clear();
        window.location.reload();
      }, 3000);
    }
    setTimeout(function () {
      setMessage("");
    }, 5000);
  }, [message]);

  const handleInputChange = (e, type) => {
    switch (type) {
      case "email":
        setError("");
        setEmail(e.target.value);
        if (e.target.value === "") {
          setError("Email has left blank");
        }
        break;
      case "password":
        setError("");
        setPassword(e.target.value);
        if (e.target.value === "") {
          setError("Password has left blank");
        }
        break;
      default:
    }
  };

  function LoginSubmit() {
    if (email !== "" && password !== "") {
      const is_laravel =
        API_BASE_URL.slice(-4) && API_BASE_URL.slice(-4) === "8000";
      var url = `${API_BASE_URL}/api/login${is_laravel ? "" : ".php"}`;
      console.log(url);
      var headers = {
        Accept: "application/json",
        "Content-type": "application/json",
      };
      var Data = {
        email: email,
        password: password,
        usertype: usertype,
      };
      fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(Data),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("Full server response:", response);
          if (response[0].result === "Not Approved") {
            alert(
              "Your account is not approved by the admin yet. Please check back later."
            );
          } else if (
            response[0].result === "Invalid username!" ||
            response[0].result === "Invalid password!" ||
            response[0].result === "Disapproved"
          ) {
            setError(response[0].result);
          } else {
            setMessage(response[0].result);
            sessionStorage.setItem(
              "userDetails",
              JSON.stringify({
                /*email: email,*/
                email: response[0].email,
                usertype: response[0].usertype,
              })
            );
            console.log("Received user type:", response[0].usertype);
            console.log("Received email:", response[0].email);
            setUsertype(response[0].usertype);
            setTimeout(function () {
              localStorage.setItem("login", true);
              localStorage.setItem("email", email);
              localStorage.setItem("usertype", response[0].usertype);
              switch (response[0].usertype) {
                case "student":
                  navigate("/Student");
                  break;
                case "instructor":
                  navigate("/Instructors");
                  break;
                case "admin":
                  navigate("/Admin");
                  break;
                case "programcoordinator":
                  navigate("/Coordinator");
                  break;
                case "qa":
                  navigate("/QA");
                  break;
                default:
                  setError("Unknown user type");
                  break;
              }
            }, 3000);
          }
        })
        .catch((error) => {
          setError(error.message); // Use the message property of the Error object
          console.log(error);
        });
    } else {
      setError("All fields are required");
    }
  }

  return (
    <>
      <NavBar />

      <div className="header">
        <h2>Login</h2>
      </div>

      <div className="login-form">
        {error !== "" ? (
          <p>
            <span className="error">{error}</span>{" "}
          </p>
        ) : (
          <p>
            <span className="success">{message}</span>
          </p>
        )}
        <div className="login-style">
          <input
            type="text"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => handleInputChange(e, "email")}
          />
        </div>

        <div className="login-style">
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => handleInputChange(e, "password")}
          />
        </div>

        <br />

        <p>
          <Link to="/ForgotPassword">Forgot Password</Link>
        </p>

        <br />

        <div className="login-style">
          <button
            type="submit"
            className="lr-btn"
            name="login_user"
            onClick={LoginSubmit}
          >
            Login
          </button>
        </div>

        <br />

        <p>
          Not yet a member? <Link to="/Register">Register</Link>
        </p>
      </div>
      {/* Display the login message
			<p>{message}</p> */}
    </>
  );
}

export default Login;
