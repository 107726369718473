import React, { Component } from 'react';
import './Qao.css'
import Header from '../Coordinator/Header'


class StudentAssessmentForm extends Component {
  render() {
    return (
      <div className="main">
          < Header authPage='qa' />

        <main className="container">
          <h2>Student Assessment Form</h2>
          <form action="" method="post">
            <label htmlFor="course">Course Name:</label>
            <input type="text" id="course" name="course" required />
            <br />

            <label htmlFor="instructor">Instructor Name:</label>
            <input type="text" id="instructor" name="instructor" required />
            <br />

            <label>1. How would you rate the overall quality of this course?</label>
            <br />
            <input
              type="radio"
              id="q1-Excellent"
              name="q1"
              value="Excellent"
              required
            />
            <label htmlFor="q1-Excellent">Excellent</label>
            <input type="radio" id="q1-Good" name="q1" value="Good" />
            <label htmlFor="q1-Good">Good</label>
            <input type="radio" id="q1-Fair" name="q1" value="Fair" />
            <label htmlFor="q1-Fair">Fair</label>
            <input type="radio" id="q1-Bad" name="q1" value="Bad" />
            <label htmlFor="q1-Bad">Bad</label>
            <input
              type="radio"
              id="q1-Very Bad"
              name="q1"
              value="Very Bad"
            />
            <label htmlFor="q1-Very Bad">Very Bad</label>
            <br />

            <label>
              2. How would you rate the effectiveness of this course on your
              overall program?
            </label>
            <br />
            <input
              type="radio"
              id="q2-Excellent"
              name="q2"
              value="Excellent"
              required
            />
            <label htmlFor="q2-Excellent">Excellent</label>
            <input type="radio" id="q2-Good" name="q2" value="Good" />
            <label htmlFor="q2-Good">Good</label>
            <input type="radio" id="q2-Fair" name="q2" value="Fair" />
            <label htmlFor="q2-Fair">Fair</label>
            <input type="radio" id="q2-Bad" name="q2" value="Bad" />
            <label htmlFor="q2-Bad">Bad</label>
            <input
              type="radio"
              id="q2-Very Bad"
              name="q2"
              value="Very Bad"
            />
            <label htmlFor="q2-Very Bad">Very Bad</label>
            <br />

            <label>3. How would your rate the overall Course curriculum?</label>
            <br />
            <input
              type="radio"
              id="q3-Excellent"
              name="q3"
              value="Excellent"
              required
            />
            <label htmlFor="q3-Excellent">Excellent</label>
            <input type="radio" id="q3-Good" name="q3" value="Good" />
            <label htmlFor="q3-Good">Good</label>
            <input type="radio" id="q3-Fair" name="q3" value="Fair" />
            <label htmlFor="q3-Fair">Fair</label>
            <input type="radio" id="q3-Bad" name="q3" value="Bad" />
            <label htmlFor="q3-Bad">Bad</label>
            <input
              type="radio"
              id="q3-Very Bad"
              name="q3"
              value="Very Bad"
            />
            <label htmlFor="q3-Very Bad">Very Bad</label>
            <br />

            <label>
              4. How would you rate the overall professors teaching methods for
              this course?
            </label>
            <br />
            <input
              type="radio"
              id="q4-Excellent"
              name="q4"
              value="Excellent"
              required
            />
            <label htmlFor="q4-Excellent">Excellent</label>
            <input type="radio" id="q4-Good" name="q4" value="Good" />
            <label htmlFor="q4-Good">Good</label>
            <input type="radio" id="q4-Fair" name="q4" value="Fair" />
            <label htmlFor="q4-Fair">Fair</label>
            <input type="radio" id="q4-Bad" name="q4" value="Bad" />
            <label htmlFor="q4-Bad">Bad</label>
            <input
              type="radio"
              id="q4-Very Bad"
              name="q4"
              value="Very Bad"
            />
            <label htmlFor="q4-Very Bad">Very Bad</label>
            <br />

            <label>
              5. How would you rate the professors responsiveness for queries?
            </label>
            <br />
            <input
              type="radio"
              id="q5-Excellent"
              name="q5"
              value="Excellent"
              required
            />
            <label htmlFor="q5-Excellent">Excellent</label>
            <input type="radio" id="q5-Good" name="q5" value="Good" />
            <label htmlFor="q5-Good">Good</label>
            <input type="radio" id="q5-Fair" name="q5" value="Fair" />
            <label htmlFor="q5-Fair">Fair</label>
            <input type="radio" id="q5-Bad" name="q5" value="Bad" />
            <label htmlFor="q5-Bad">Bad</label>
            <input
              type="radio"
              id="q5-Very Bad"
              name="q5"
              value="Very Bad"
            />
            <label htmlFor="q5-Very Bad">Very Bad</label>
            <br />

            <button type="submit">Submit</button>
          </form>
        </main>
      </div>
    );
  }
}

export default StudentAssessmentForm;
