import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import logo from '../assets/images/logo.png';
import API_BASE_URL from '../config';
import Header from '../Coordinator/Header';

function Contact() {
  const [assignments, setAssignments] = useState([]);
  const headers = [
    { key: 'id', label: 'ID' },
    { key: 'AssignmentName', label: 'Assignment Name' },
    { key: 'assignment_id', label: 'Assignment ID' },
    { key: 'CourseID', label: 'Course ID' },
    { key: 'Duedate', label: 'Due Date' },
    { key: 'file_path', label: 'File Path' },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/assignment.php`);
        setAssignments(response.data);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const deleteAssignment = async (id, event) => {
    event.preventDefault();
    if (window.confirm("Are you sure you want to delete?")) {
      try {
        const response = await axios.post(`${API_BASE_URL}/api/assignment.php/?delete=${id}`);
        console.log(response);
        if (response.status === 200) {
          alert("Assignment deleted successfully");
          // Optionally, update the assignments state to reflect the deletion
          setAssignments(assignments.filter(item => item.id !== id));
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div className="main">
          < Header authPage='instructor' />

      <div></div>
      <div className="container">
        <h1>Assignments</h1>
        <p>
          <Link to="/CreateHw" className="update">Add Assignment</Link>
        </p>
        <table className="PreviousPolicies">
          <thead>
            <tr>
              {headers.map((h) => (
                <th key={h.key}>{h.label}</th>
              ))}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {assignments.map((item, key) => (
              <tr key={key}>
                <td>{item.id}</td>
                <td>{item.AssignmentName}</td>
                <td>{item.assignment_id}</td>
                <td>{item.CourseID}</td>
                <td>{item.Duedate}</td>
                <td>{item.file_path}</td>
                <td>
                  <Link to={`/updatehw/${item.id}`} className="update">Edit</Link>
                </td>
                <td>
                  <Link to="#" onClick={(event) => deleteAssignment(item.id, event)} className="delete">Delete</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Contact;
