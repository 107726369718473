import React from 'react';
import { Link } from 'react-router-dom';
import CoursesPerformance from '../assets/images/courses-performance-graph.gif'
import Header from './Header';

function CoursePerformance() {

    return (
        <div>
            < Header authPage='programcoordinator' />

            <header>
                <h1>Courses Performance</h1>
            </header>

            <div className="image-container">
                <br></br>
                <img
                    src={CoursesPerformance}
                    alt="Courses Performance Graph"
                />
            </div>

            <Link to="/PerformanceMonitoring" className="button">Back to Performance Monitoring</Link>

        </div>
    );
}

export default CoursePerformance;
