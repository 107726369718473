import React, { useState } from 'react';
import './instructor.css';
import { Link } from "react-router-dom";
import logo from '../Student/images/logo.png';
import Header from '../Coordinator/Header';

function App() {
  const [students, setStudents] = useState(Array(10).fill([]));

  const calculateGrade = (studentIndex, courseIndex) => {
    const newStudents = [...students];
    const marksValue = parseFloat(newStudents[studentIndex][courseIndex].marks);
    if (!isNaN(marksValue)) {
      if (marksValue >= 90) {
        newStudents[studentIndex][courseIndex].grade = 'A';
      } else if (marksValue >= 80) {
        newStudents[studentIndex][courseIndex].grade = 'B';
      } else if (marksValue >= 70) {
        newStudents[studentIndex][courseIndex].grade = 'C';
      } else if (marksValue >= 60) {
        newStudents[studentIndex][courseIndex].grade = 'D';
      } else {
        newStudents[studentIndex][courseIndex].grade = 'F';
      }
    } else {
      newStudents[studentIndex][courseIndex].grade = 'Invalid';
    }
    setStudents(newStudents);
  };

  const addCourse = (studentIndex) => {
    const newStudents = [...students];
    newStudents[studentIndex].push({ courseName: '', marks: '', grade: '' });
    setStudents(newStudents);
  };

  const addStudent = () => {
    setStudents([...students, []]);
  };

  const handleCourseNameChange = (studentIndex, courseIndex, value) => {
    const newStudents = [...students];
    newStudents[studentIndex][courseIndex].courseName = value;
    setStudents(newStudents);
  };

  const handleMarksChange = (studentIndex, courseIndex, value) => {
    const newStudents = [...students];
    newStudents[studentIndex][courseIndex].marks = value;
    setStudents(newStudents);
  };

  return (
    <div>
      <div className="main">
        < Header authPage='instructor' />

        <h1>Student Grade Calculator for 10 Students</h1>
        {students.map((student, studentIndex) => (
          <div key={studentIndex}>
            <h2>Student {studentIndex + 1}</h2>
            {student.map((course, courseIndex) => (
              <div key={courseIndex}>
                <label>Course Name:</label>
                <input
                  type="text"
                  value={course.courseName}
                  onChange={(e) =>
                    handleCourseNameChange(studentIndex, courseIndex, e.target.value)
                  }
                />
                <label>Enter Marks:</label>
                <input
                  type="number"
                  value={course.marks}
                  onChange={(e) =>
                    handleMarksChange(studentIndex, courseIndex, e.target.value)
                  }
                />
                <button onClick={() => calculateGrade(studentIndex, courseIndex)}>
                  Calculate Grade
                </button>
                <p>Grade: {course.grade}</p>
              </div>
            ))}
            <button onClick={() => addCourse(studentIndex)}>Add Course</button>
          </div>
        ))}
        <button onClick={addStudent}>Add Student</button>
      </div>
    </div>
  );
}

export default App;
