import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../config';
import Header from '../Coordinator/Header';

const UpdateExam = () => {
  const { id } = useParams(); // Get the 'examId' parameter from the URL

  const [exam, setExam] = useState({
    ExamName: '',
    Examid: '',
    CourseID: '',
    Duedate: '',
    Marks: '',
  });

  useEffect(() => {
    // Fetch exam data when the component mounts
    axios
      .get(`${API_BASE_URL}/api/exams.php/?delete=${id}`)
      .then((response) => response.data)
      .then((data) => {
        setExam(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [id]); // Make sure to include 'examId' in the dependency array

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setExam({
      ...exam,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('ExamName', exam.ExamName);
    formData.append('Examid', exam.Examid);
    formData.append('CourseID', exam.CourseID);
    formData.append('Duedate', exam.Duedate);
    formData.append('Marks', exam.Marks);

    axios
      .post(`${API_BASE_URL}/api/exams.php/?id=${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(function (response) {
        if (response.status === 200) {
          alert('Exam updated successfully.');
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  return (
    <div className="container">
      <h1 className="page-header text-center">Update Exam</h1>
      <Link to="/exams" className="update">
        Home
      </Link>
      <div className="col-md-12">
        <div className="panel panel-primary">
          <div className="panel-body">
            <form onSubmit={handleSubmit}>
              <label>Exam Name:</label>
              <input
                type="text"
                name="ExamName"
                className="form-control"
                value={exam.ExamName}
                onChange={handleInputChange}
              />

              <label>Exam ID:</label>
              <input
                type="text"
                name="Examid"
                className="form-control"
                value={exam.Examid}
                onChange={handleInputChange}
              />

              <label>Course ID:</label>
              <input
                type="text"
                name="CourseID"
                className="form-control"
                value={exam.CourseID}
                onChange={handleInputChange}
              />

              <label>Due Date:</label>
              <input
                type="text"
                name="Duedate"
                className="form-control"
                value={exam.Duedate}
                onChange={handleInputChange}
              />

              <label>Marks:</label>
              <input
                type="text"
                name="Marks"
                className="form-control"
                value={exam.Marks}
                onChange={handleInputChange}
              />
              <br />
              <input type="submit" className="update" value="Update" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateExam;
