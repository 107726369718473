import React, { useState, useEffect } from 'react';
import { Link, useParams} from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../config';
import Header from '../Coordinator/Header';

const UpdateAssignment = () => {
  const { id } = useParams(); // Get the 'assignmentId' parameter from the URL
  

  const [assignment, setAssignment] = useState({
    AssignmentName: '',
    assignment_id: '',
    CourseID: '',
    Duedate: '',
    file_path: '',
  });

  useEffect(() => {
    // Fetch assignment data when the component mounts
    axios
      .get(`${API_BASE_URL}/api/assignment.php/?id=${id}`)
      .then((response) => response.data)
      .then((data) => {
        setAssignment(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [id]); // Make sure to include 'assignmentId' in the dependency array

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAssignment({
      ...assignment,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('AssignmentName', assignment.AssignmentName);
    formData.append('assignment_id', assignment.assignment_id);
    formData.append('CourseID', assignment.CourseID);
    formData.append('Duedate', assignment.Duedate);
    formData.append('file_path', assignment.file_path);

    axios
      .post(`${API_BASE_URL}/api/assignment.php/?id=${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(function (response) {
        if (response.status === 200) {
          alert('Assignment updated successfully.');
          
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  return (
    <div className="container">
      <h1 className="page-header text-center">Update Assignment</h1>
      <Link to="/Assignments" className="update">
        Home
      </Link>
      <div className="col-md-12">
        <div className="panel panel-primary">
          <div className="panel-body">
            <form onSubmit={handleSubmit}>
              <label>Assignment Name:</label>
              <input
                type="text"
                name="AssignmentName"
                className="form-control"
                value={assignment.AssignmentName}
                onChange={handleInputChange}
              />

              <label>Assignment ID:</label>
              <input
                type="text"
                name="assignment_id"
                className="form-control"
                value={assignment.assignment_id}
                onChange={handleInputChange}
              />

              <label>Course ID:</label>
              <input
                type="text"
                name="CourseID"
                className="form-control"
                value={assignment.CourseID}
                onChange={handleInputChange}
              />

              <label>Due Date:</label>
              <input
                type="text"
                name="Duedate"
                className="form-control"
                value={assignment.Duedate}
                onChange={handleInputChange}
              />

              <label>File Path:</label>
              <input
                type="text"
                name="file_path"
                className="form-control"
                value={assignment.file_path}
                onChange={handleInputChange}
              />
              <br />
              <input type="submit" className="update" value="Update" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateAssignment;
