import React from 'react';
import { Link } from 'react-router-dom';
import StudentGraph from '../assets/images/student-performance-graph.gif'

function StudentsPerformance() {
    return (
        <div>
            <header>
                <h1>Students Performance</h1>
            </header>

            <div className="image-container">
                <br></br>
                <img
                    src={StudentGraph}
                    alt="Student Performance Graph"
                />
            </div>

            <Link to="/PerformanceMonitoring" className="button">Back to Performance Monitoring</Link>

        </div>
    );
}

export default StudentsPerformance;