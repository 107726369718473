import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../config';
import Header from '../Coordinator/Header';
import logo from '../assets/images/logo.png';

function InstructorProfile() {
  const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  const userEmail = userDetails && userDetails.email;
  const [profileData, setProfileData] = useState({});
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({
    name: profileData.name || '',
    email: profileData.email || '',
    dob: profileData.dob || '',
    phone: profileData.phone || '',
    officeHours: profileData.officeHours || '',
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setEditedData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    // Here, you would typically make an API call to save the changes
    // For simplicity, we'll update the local profile data
    setProfileData(editedData);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    if (userEmail) {
      fetch(`${API_BASE_URL}/api/instructorprofile.php`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ email: userEmail }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            setProfileData(data);
          } else {
            console.error(data.error);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [userEmail]);

  const handleLogout = () => {
    sessionStorage.removeItem('userDetails'); // Clear the userDetails
    navigate('/Login'); // Redirect to the Login page using navigate
  };

  return (
    <>
      <Header authPage="instructor" />

      <div className="s-student-profile">
        <h2>Instructor Profile</h2>
        <input type="checkbox" id="edit-mode" hidden={isEditing} />
        <div className="s-profile-info">
          <label>Name:</label>
          {isEditing ? (
            <input
              id="name"
              value={editedData.name}
              onChange={handleInputChange}
              placeholder="Name"
            />
          ) : (
            <span>{profileData.name}</span>
          )}

          <label>Email:</label>
          {isEditing ? (
            <input
              id="email"
              value={editedData.email}
              onChange={handleInputChange}
              placeholder="Email"
            />
          ) : (
            <span>{profileData.email}</span>
          )}

          <label>Date of birth:</label>
          {isEditing ? (
            <input
              id="dob"
              value={editedData.dob}
              onChange={handleInputChange}
              placeholder="Dob"
            />
          ) : (
            <span>{profileData.dob}</span>
          )}

          <label>Phone number:</label>
          {isEditing ? (
            <input
              id="phone"
              value={editedData.phone}
              onChange={handleInputChange}
              placeholder="Phone"
            />
          ) : (
            <span>{profileData.phone}</span>
          )}

          <label>Office hours:</label>
          {isEditing ? (
            <input
              id="officeHours"
              value={editedData.officeHours}
              onChange={handleInputChange}
              placeholder="Office Hours"
            />
          ) : (
            <span>{profileData.officeHours}</span>
          )}

          <div className="btn-container">
            {!isEditing && <button onClick={handleEdit}>Edit</button>}
            {isEditing && (
              <>
                <button onClick={handleSave}>Save</button>
                <button onClick={handleCancel}>Cancel</button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default InstructorProfile;
