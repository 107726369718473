import React from "react";
import ReactDOM from "react-dom/client";
// import './index.css';
import App from "./App";
// import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  //   <BrowserRouter>
  //     <AuthProvider>
  //       <Routes>
  //         <Route path="/*" element={<App />} />
  //       </Routes>
  //     </AuthProvider>
  //   </BrowserRouter>
  // </React.StrictMode>

  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
