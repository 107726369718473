import React from 'react';
import './instructor.css'
import Header from '../Coordinator/Header';
import { Link } from "react-router-dom";

function InstructorPage() {
  return (
    <div>
      <div className="main">
      < Header authPage='instructor' />

        <div className="col">
          <div className="Tab Tab8">
            <div className="content-wrapper">
              <a href="/" target="_blank"> Modules </a>
            </div>
          </div>
          <div className="Tab Tab9">
            <div className="content-wrapper">
              <Link to="/Exams">Exams</Link>
            </div>
          </div>
          <div className="Tab Tab10">
            <div className="content-wrapper">
              <Link to="/Assignments">Assignments</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstructorPage;
