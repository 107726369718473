import React from 'react';
import './instructor.css'
import Header from '../Coordinator/Header';

function UpcomingAppointments() {
  return (
    <div className="main">

      < Header authPage='instructor' />

      <div>
        <header>
          <h1>Upcoming Appointments</h1>
        </header>
        <main>
          <section className="appointment">
            <h2>Appointment with Student 1</h2>
            <p>Date: October 10, 2023</p>
            <p>Time: 2:00 PM</p>
            <p>Location: Room 101</p>
          </section>
          <section className="appointment">
            <h2>Appointment with Student 2</h2>
            <p>Date: October 15, 2023</p>
            <p>Time: 3:30 PM</p>
            <p>Location: Room 105</p>
          </section>
        </main>
      </div>
    </div>
  );
}

export default UpcomingAppointments;
