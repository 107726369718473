import React, { useEffect, useState } from 'react'
import styles from './adminProfile.module.css'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import API_BASE_URL from '../config';
import Header from '../Coordinator/Header'

function AdminEditProfile() {

  const { email } = useParams();
  const [formData, setFormData] = useState(
    {
      firstname: '',
      email: '',
      dob: '',
      mobile: '',
    }
  );

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/getUserData.php/?email=${email}`)
      .then((response) => response.data)
      .then((data) => {
        setFormData(data);
        console.log(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("hello");
    console.log(formData);
    const formDetails = new FormData();
    formDetails.append("firstname", formData.firstname);
    formDetails.append("dob", formData.dob);
    formDetails.append("mobile", formData.mobile);
    formDetails.append("email", email);

    axios
      .post(`${API_BASE_URL}/api/saveChanges.php`, formDetails, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(function (response) {
        if (response.status === 200) {
          alert('Exam updated successfully.');
        } else if (response.data == "Not done properly") {
          alert("failed");
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  return (
    <>
      < Header authPage='admin' />

      <div className={styles.studentProfile}>

        <h2>Admin Profile</h2>

        <div className={styles.profileInfo}>
          <form style={{ width: '100%' }} onSubmit={handleSubmit}>
            <label htmlFor="name">Name:</label>
            <input className={styles.editField} type="text" id="name-input" name="firstname" value={formData.firstname} onChange={handleInputChange} />

            <label htmlFor="email">Email:</label>
            <input className={styles.editField} type="text" id="email-input" value={formData.email} disabled />

            <label htmlFor="dob">Date of birth:</label>
            <input className={styles.editField} type="text" id="dob" name="dob" value={formData.dob} onChange={handleInputChange} />

            <label htmlFor="pno">Phone no:</label>
            <input className={styles.editField} type="text" id="pno" name="mobile" value={formData.mobile} onChange={handleInputChange} />

            <button type="submit">Save Changes</button>
          </form>
        </div>
      </div>
    </>

  )
}

export default AdminEditProfile
