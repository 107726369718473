import React from 'react';
import { Link } from 'react-router-dom';
import '../../Student/lrstyle.css';
import { useState } from 'react';
// import logo from '../../Student/images/logo.png';
import NavBar from '../navbar';
import axios from 'axios';
import API_BASE_URL from '../../config';

function StudentRegister() {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirm, setPasswordConfirm] = useState('');
  const [usertype, setUsertype] = useState('');
  const [dob, setDob] = useState('');
  const [mobile, setMobile] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      firstname.length === 0 ||
      lastname.length === 0 ||
      password.length === 0 ||
      mobile.length === 0 ||
      email.length === 0 ||
      dob.length === 0 ||
      usertype.length === 0
    ) {
      alert('Please fill in all fields.');
    }
    // Validating the names
    if (firstname.trim() === '' || lastname.trim() === '') {
      alert('Please enter your first and last name.');
      return;
    }
    if (!/^[A-Za-z\s]+$/.test(firstname) || !/^[A-Za-z\s]+$/.test(lastname)) {
      alert('First and last name should contain only letters and spaces.');
      return;
    }
    // Validating passwords
    if (password !== password_confirm) {
      alert('Password and confirm password do not match.');
      return;
    }
    if (password.length < 8) {
      alert('Password should be at least 8 characters long.');
      return;
    }
    if (usertype === '') {
      alert('Please select a user type from the drop-down menu.');
      return;
    }

    // Validating date of birth
    if (dob.trim() === '') {
      alert('Please enter your date of birth');
      return;
    }
    const currentDate = new Date();
    const dobDate = new Date(dob);
    const yearsDiff = currentDate.getFullYear() - dobDate.getFullYear();

    if (yearsDiff < 16 || yearsDiff > 120) {
      alert('Age must be between 16 and 120 years');
      return;
    }

    // Validate US phone number format (XXX-XXX-XXXX)
    if (!/^\d{3}-\d{3}-\d{4}$/.test(mobile)) {
      alert('Please enter a valid phone number in the format XXX-XXX-XXXX.');
      return;
    }

    const is_laravel = API_BASE_URL.slice(-4,) && API_BASE_URL.slice(-4,) === '8000';
    const url = `${API_BASE_URL}/api/register${is_laravel ? '' : '.php'}`;

    const formData = new FormData();
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('usertype', usertype);
    formData.append('dob', dob);
    formData.append('mobile', mobile);
    console.log(url);

    axios
      .post(url, formData)
      .then((response) => {
        alert(JSON.stringify(response.data));
        // You can add additional actions on successful registration here
      })
      .catch((error) => {
        console.error('Error in register.js:', error);
        alert(`Error: ${error.message}`);
      });

  };

  return (
    <div>
      <div className="lr-main">
        <NavBar />
      </div>
      <div className="header">
        <h2>Register</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="lr-input-group">
          <input
            type="text"
            id="firstname"
            name="firstname"
            placeholder="First Name"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />
        </div>
        <div className="lr-input-group">
          <input
            type="text"
            id="lastname"
            name="lastname"
            placeholder="Last Name"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
        </div>
        <div className="lr-input-group">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="lr-input-group">
          <input
            type="password"
            name="password_1"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="lr-input-group">
          <input
            type="password"
            name="password_2"
            placeholder="Confirm Password"
            value={password_confirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
        </div>

        <div className="lr-input-group">
          <select
            id="role"
            name="role"
            value={usertype}
            onChange={(e) => setUsertype(e.target.value)}
          >
            <option value="">User Type</option>
            <option value="student">Student</option>
            <option value="instructor">Instructor</option>
            <option value="admin">Admin</option>
            <option value="programcoordinator">Program Coordinator</option>
            <option value="qa">Quality Assurance</option>
          </select>
        </div>
        <div className="lr-input-group">
          <label>Date of Birth: </label>
          <input
            type="date"
            name="dob"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
          />
        </div>
        <div className="lr-input-group">
          <input
            type="text"
            name="phno"
            placeholder="Phone no"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </div>
        <div className="lr-input-group">
          <button type="submit" className="lr-btn" name="reg_user">
            Register
          </button>
        </div>
        <p>
          Already a member? <Link to="/Login">Login</Link>
        </p>
      </form>
    </div>
  );
}

export default StudentRegister;
