import React from "react";
import { Link } from "react-router-dom";
import './instructor.css'
import Header from '../Coordinator/Header';

class InstructorPage extends React.Component {
  render() {
    return (
      <div>
        <head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </head>
        <div className="main">
          < Header authPage='instructor' />

          <div className="form">
            <Link to="/Personal">
              <button className="btnn">INSTRUCTOR PROFILE</button>
            </Link>
            <Link to="/Appointments">
              <button className="btnn">APPOINTMENTS</button>
            </Link>
            <Link to="/Course1">
              <button className="btnn">COURSES</button>
            </Link>
            <Link to="/Announcments">
              <button className="btnn">ANNOUNCMENTS</button>
            </Link>
            <Link to="/Feedback">
              <button className="btnn">STUDENT SUPPORT FEEDBACK</button>
            </Link>
            <Link to="/chat">
              <button className="btnn">CHAT</button>
            </Link>
            <Link to="/Report">
              <button className="btnn">REPORTS</button>
            </Link>
            <Link to="/Grades">
              <button className="btnn">Grades</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}


export default InstructorPage;
