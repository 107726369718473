import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../config';
import logo from '../Student/images/logo.png';
//import '../Instructor/coursesstyle.css';
import Header from '../Coordinator/Header';

function CoursesPage() {
  const [courseName, setCourseName] = useState('');
  const [courseDescription, setCourseDescription] = useState('');
  const [courseID, setCourseID] = useState('');

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Prepare data to send to the backend
    const formData = new FormData();
    formData.append('CourseName', courseName);
    formData.append('CourseID', courseID);
    formData.append('CourseDescription', courseDescription);

    axios({
      method: 'post',
      url: `${API_BASE_URL}/api/Course.php`, // Change the URL to the exam API
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then(function (response) {
        // Handle success
        console.log(response);
        alert('New Course Successfully Added.');
      })
      .catch(function (error) {
        // Handle error
        console.error(error);
      });
  };

  return (
    <div>
      <div className="main">
      < Header authPage='instructor' />

        

        <section className="course-list">
          <h3>My Courses</h3>
          <div className="col">
            <a href="/Course1">
              <div className="Tab Tab5"></div>
            </a>
          </div>
        </section>

        <section className="container">
          <h2>Create Course</h2>
          <form id="createCourseForm" onSubmit={handleFormSubmit}>
            <label htmlFor="courseID">Course ID:</label>
            <input
              type="text"
              id="courseID"
              name="courseID"
              value={courseID}
              onChange={(e) => setCourseID(e.target.value)}
              required
            />
            <label htmlFor="courseName">Course Name:</label>
            <input
              type="text"
              id="courseName"
              name="courseName"
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
              required
            />
            <h3>
              <label htmlFor="courseDescription">Course Description:</label>
            </h3>
            <textarea
              id="courseDescription"
              name="courseDescription"
              value={courseDescription}
              onChange={(e) => setCourseDescription(e.target.value)}
              required
            />
            <button type="submit">Create</button>
          </form>
        </section>
      </div>
    </div>
  );
}

export default CoursesPage;
