import React, { useState, useEffect } from "react";
import "./defaultStyle.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";

const Header = (props) => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Set this based on your authentication logic

  const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
  const userEmail = userDetails && userDetails.email;
  const navigate = useNavigate(); // Using React Router's useNavigate

  const userType = userDetails && userDetails.usertype;

  useEffect(() => {
    if (userType && props.authPage && userType !== props.authPage) {
      console.log(`Unauthorized!!!!! ${userType}`);
      switch (userType) {
        case "student":
          navigate("/Student");
          break;
        case "instructor":
          navigate("/Instructors");
          break;
        case "admin":
          navigate("/Admin");
          break;
        case "programcoordinator":
          navigate("/Coordinator");
          break;
        case "qa":
          navigate("/QA");
          break;
        default:
          navigate("/");
          break;
      }
    }
  }, []);

  const handleLogout = () => {
    sessionStorage.removeItem("userDetails"); // Clear the userDetails
    navigate("/Login"); // Redirect to the Login page using navigate
    // setIsLoggedIn(false);
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  if (!isLoggedIn) {
    handleLogin();
  }

  return (
    <div className="navbar">
      <div className="icon">
        <h2 className="logo">
          <Link to="/">
            <img src={logo} alt="Edumetric" />
          </Link>
        </h2>
      </div>
      <div className="headermenu">
        <ul>
          {location.pathname !== "/Coordinator" && (
            <li>
              <Link to="/Coordinator">Home</Link>
            </li>
          )}

          {location.pathname !== "/About" && (
            <li>
              <Link to="/About">About</Link>
            </li>
          )}

          {location.pathname !== "/Services" && (
            <li>
              <Link to="/Services">Services</Link>
            </li>
          )}

          {location.pathname !== "/Contact" && (
            <li>
              <Link to="/Contact">Contact</Link>
            </li>
          )}

          {
            <li>
              <Link to="http://sxg1200.uta.cloud" target="__blank">
                Blog
              </Link>
            </li>
          }

          {userEmail && <li>Logged in as: {userEmail}</li>}

          {userEmail && (
            <li onClick={handleLogout}>
              <Link>Logout</Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Header;
