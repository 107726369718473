import React from 'react';
import './instructor.css'
import Header from '../Coordinator/Header';

function StudentFeedback() {
  return (
    <div className="main">
          < Header authPage='instructor' />

      <div>
        <section className="container">
          <h2>Feedback to Student</h2>
          <form id="StudentFeedback">
            <label htmlFor="FeedbackID">Feedback of:</label>
            <input type="text" id="FeedbackID" name="Feedback" required />

            <p><label htmlFor="StudentID">Student ID:</label></p>
            <input type="text" id="StudentID" name="Student ID" required />

            <p><label htmlFor="ExamAssessmentScore">Exam/Assessment Score:</label></p>
            <input type="text" id="ExamAssessmentScore" name="Exam/Assessment Score" required />
          </form>

          <div>
            <main>
              <form action="" method="post">
                <label htmlFor="message">Feedback Message:</label>
                <textarea id="message" name="message" rows="10" required></textarea>

                <button id="postFeedback">Submit</button>
              </form>
            </main>
          </div>
        </section>
      </div>
    </div>
  );
}

export default StudentFeedback;
